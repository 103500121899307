import { AiOutlineCheckCircle } from "react-icons/ai";
import StatusFlag from "../utils/StatusFlag";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { RootState } from "../../store";
import { Project } from "../../store/project/types";


const ProjectSummary: React.FC = () => {
  const dispatch = useDispatch();
  const { projectId, requirementId } = useParams<{
    projectId: string;
    requirementId: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();
  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  return (
    <div className="px-4 pt-2 pb-8">
      <div className="sticky top-0 pt-4 bg-white/90 z-10 flex items-start rfi-header">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">Project Summary</h2>
          {project?.requirement?.isScopeApproved && (
            <StatusFlag status="approved" />
          )}
        </div>
        
          <div className="flex ml-4 gap-4">
            <button
              className="button-secondary bg-brandYellow border !border-yellow-500 flex items-center gap-2 px-4 shadow-sm rounded-lg text-lg hover:bg-yellow-50 focus:outline-none focus:ring focus:ring-green-200"
              
            >
              Some button
            </button>

            
              <button
                className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
                
              >
                <AiOutlineCheckCircle
                  className="cursor-pointer"
                  size={20}
                  title="Approve"
                />
                Approve
              </button>
            
          </div>
        
      </div>

      
    </div>
  );

};

export default ProjectSummary;