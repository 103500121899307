import { Outlet, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useGetProjectByIdQuery,
  useGetProposalSettingsQuery,
  useGetQuoteQuery,
  useGetRequestResourceRequirementsQuery,
} from "../store/project/projectApiService";
import {
  setCurrentProject,
  setCurrentQuote,
  setCurrentResourceSummary,
  setProposalSettings,
} from "../store/project/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { Project } from "../store/project/types";
import NewOpportunity from "./NewOpportunity";
import { RootState } from "../store";
import { DashboardBusinessOverview } from "./DashboardBusinessOverview";
import { DashboardTechnicalOverview } from "./DashboardTechnicalOverview";
import { DashboardDataOverview } from "./DashboardDataOverview";

const Dashboard = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const isChildRoute =
    location.pathname.includes("questionnaires") ||
    location.pathname.includes("work-breakdown-structure") ||
    location.pathname.includes("scoping") ||
    location.pathname.includes("resource-planning") ||
    location.pathname.includes("quote") ||
    location.pathname.includes("summary") ||
    location.pathname.includes("proposal");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const { data: requestProposalSettings } = useGetProposalSettingsQuery(
    project?.requirement?.id!,
    {
      skip: project?.requirement?.id == null,
    }
  );

  useEffect(() => {
    if (requestProposalSettings) {
      dispatch(
        setProposalSettings({
          requirementId: project?.requirement?.id!,
          allDocuments: requestProposalSettings.data.allDocuments,
          activeDocument: requestProposalSettings.data.activeDocument,
        })
      );
    }
  }, [project?.requirement?.id, requestProposalSettings, dispatch]);

  const { data: requestResourceSummaryData } =
    useGetRequestResourceRequirementsQuery(project?.requirement?.id || "", {
      skip: !project?.requirement?.id,
    });

  useEffect(() => {
    if (requestResourceSummaryData) {
      dispatch(setCurrentResourceSummary(requestResourceSummaryData.data));
    }
  }, [requestResourceSummaryData, dispatch]);

  const { data: quoteRequestData } = useGetQuoteQuery(
    project?.requirement?.id || "",
    {
      skip: !project?.requirement?.id,
    }
  );

  useEffect(() => {
    if (quoteRequestData) {
      dispatch(
        setCurrentQuote({
          requirementId: project?.requirement?.id!,
          quote: quoteRequestData.data,
        })
      );
    }
  }, [project?.requirement?.id, quoteRequestData, dispatch]);

  const { data: requestProjectData } = useGetProjectByIdQuery(projectId!, {
    skip: projectId == null,
  });

  useEffect(() => {
    if (requestProjectData) {
      dispatch(setCurrentProject(requestProjectData.data));
    }
  }, [requestProjectData, dispatch]);

  if (isChildRoute) {
    return <Outlet />;
  }
  return (
    <div>
      <div className="p-4 mt-2">
        <div className="flex w-full mb-6">
          <h1 className="grow cursor-pointer">{project?.title}</h1>

          <button onClick={toggleDrawer} className="button-secondary">
            <img src="/icons/edit.png" className="w-5 h-5" alt="" />
            Edit Project
          </button>
          <NewOpportunity
            isOpen={isOpen}
            toggleDrawer={toggleDrawer}
            project={project}
          />
        </div>

        <div>
          <DashboardDataOverview project={project} />
          <DashboardTechnicalOverview project={project} />
          <DashboardBusinessOverview project={project} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
