import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useUploadQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useUpdateQuestionnaireTitleMutation,
  useGetQuestionnairesQuery,
} from "../../store/questionnaire/questionnaireApiService";
import { setCurrentProject } from "../../store/project/projectSlice";
import { RootState } from "../../store";
import { Questionnaire } from "../../store/questionnaire/types";
import { toast } from "react-toastify";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
} from "react-icons/ai";
import {
  projectApiUtils,
  useGetProjectByIdQuery,
  useGetQuestionnairesByProjectIdQuery,
  useUpdateProjectMutation,
} from "../../store/project/projectApiService";
import { Project } from "../../store/project/types";
import { downloadTemplateApi } from "../../store/capabilityCheck/capabilityCheckApiService";
import { logEvent } from "../../utils";
import { setQuestionnaires } from "../../store/questionnaire/questionnaireSlice";
import { sum } from "lodash";

const Questionnaires = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [uploadQuestionnaire] = useUploadQuestionnaireMutation();
  const [deleteQuestionnaire] = useDeleteQuestionnaireMutation();
  const [updateQuestionnaireTitle] = useUpdateQuestionnaireTitleMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [isProjectEditable, setIsProjectEditable] = useState<boolean>(false);
  const [projectTitle, setProjectTitle] = useState<string>("");

  const location = useLocation();

  // Check if the current path corresponds to a child route
  const isChildRoute =
    location.pathname.includes("capability-checks") ||
    location.pathname.includes("library");

  const questionnaires = useSelector<RootState, Questionnaire[] | null>(
    (state) => {
      return state.questionnaireData.questionnaires;
    }
  );

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  const { data: requestProjectData } = useGetProjectByIdQuery(projectId!, {
    skip: projectId == null,
  });

  useEffect(() => {
    if (requestProjectData) {
      dispatch(setCurrentProject(requestProjectData.data));
    }
  }, [requestProjectData, dispatch]);

  const { data: requestQuestionnairesData } = useGetQuestionnairesQuery();

  useEffect(() => {
    if (requestQuestionnairesData) {
      dispatch(setQuestionnaires(requestQuestionnairesData));
    }
  }, [requestQuestionnairesData, dispatch]);

  const resetProjectEditModel = () => {
    setIsProjectEditable(false);
    setProjectTitle("");
  };

  const sendUpdateUprojectChanges = () => {
    if (isProjectEditable) {
      const updatedProject = {
        id: projectId!,
        title: projectTitle,
        summary: "",
      };

      updateProject(updatedProject)
        .unwrap()
        .then(() => {
          dispatch(setCurrentProject(updatedProject));
          resetProjectEditModel();
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
        });
    }
  };

  const resetEditModel = () => {
    setEditingId(null);
    setEditingTitle("");
  };

  const sendUpdateQuestionnaire = () => {
    if (editingId) {
      const updatedQuestionnaire = {
        id: editingId,
        title: editingTitle,
        completion: 0,
        score: 0,
      };
      const newProjectList = questionnaires!.map((item) =>
        item.id === editingId ? { ...item, ...updatedQuestionnaire } : item
      );
      dispatch(setQuestionnaires(newProjectList));
      updateQuestionnaireTitle(updatedQuestionnaire)
        .unwrap()
        .then(() => {
          const newDocumentList = questionnaires!.map((item) => {
            if (item.id === editingId) {
              return updatedQuestionnaire;
            }
            return item;
          });
          dispatch(setQuestionnaires(newDocumentList));
        });
    }
    resetEditModel();
  };

  const onProjectTitleEnterPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Enter") {
      sendUpdateUprojectChanges();
    } else if (e.key === "Escape") {
      resetProjectEditModel();
    }
  };

  const stratEditing = (item: Questionnaire) => {
    setEditingId(item.id);
    setEditingTitle(item.title);
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendUpdateQuestionnaire();
      logEvent("Questionnaire Title Updated", {
        questionnaireId: editingId,
      });
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const stratEditingProjectTitle = () => {
    setIsProjectEditable(true);
    setProjectTitle(project?.title || "");
  };

  const handleRowClick = (id: string) => {
    if (!editingId && !deletingId) {
      navigate(`capability-checks/${id}`);
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      uploadQuestionnaire({
        projectId: projectId!,
        file: acceptedFiles[0],
      })
        .unwrap()
        .then((questionnaire: Questionnaire) => {
          logEvent("Questionnaire Uploaded", {
            questionnaireId: questionnaire.id,
          });
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "Project", id: projectId! },
            ])
          );
          navigate(`capability-checks/${questionnaire.id}`);
        })
        .catch((err: any) => {
          logEvent("Questionnaire Upload Failed", {
            error: err?.data?.message,
          });
          toast.error(err?.data?.message || "Error!");
        });
    },
    [projectId, uploadQuestionnaire, navigate, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  if (!questionnaires) return null;

  const getProgressColor = (percentage: number) => {
    if (percentage <= 33) {
      return "bg-red-fail";
    } else if (percentage <= 66) {
      return "bg-yellow-500";
    } else {
      return "bg-green-success";
    }
  };

  const getFileType = (fileName: string) => {
    const ext = fileName.split(".").pop();
    if (ext === "xlsx") {
      return "/icons/ico-xlsx.png";
    } else if (ext === "pdf") {
      return "/icons/ico-pdf.png";
    } else if (ext === "docx") {
      return "/icons/ico-docx.png";
    } else {
      return "/icons/ico-file.png";
    }
  };

  const handleDeleteClick = (docId: string) => {
    setDeletingId(docId);
  };

  const handleConfirmDelete = (rfpId: string) => {
    deleteQuestionnaire(rfpId)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        logEvent("Questionnaire Deleted");
        const newProjectList = questionnaires!.filter(
          (item) => item.id !== rfpId
        );
        dispatch(setQuestionnaires(newProjectList));
        dispatch(
          projectApiUtils.invalidateTags([{ type: "Project", id: projectId! }])
        );
        setDeletingId("");
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const handleDownloadTemplate = () => {
    logEvent("Questionnaire Downloaded Template");
    downloadTemplateApi();
  };

  return (
    <div>
      {!isChildRoute && (
        <div className="p-4 mt-4">
          <div className="flex w-full mb-6">
            {isProjectEditable ? (
              <div className="grow relative">
                <input
                  type="text"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                  autoFocus
                  className="block w-full py-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50"
                  onKeyUp={onProjectTitleEnterPress}
                />
                <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                  <AiOutlineCheck
                    onClick={sendUpdateUprojectChanges}
                    size={20}
                    className="hover:text-green-500 cursor-pointer"
                  />
                  <AiOutlineClose
                    onClick={resetProjectEditModel}
                    size={20}
                    className="hover:text-red-500 cursor-pointer"
                  />
                </div>
              </div>
            ) : (
              <h1 className="grow">Questionnaires</h1>
            )}

            <button
              onClick={handleDownloadTemplate}
              className="button-secondary"
            >
              <img src="/icons/download.png" className="w-5 h-5" alt="" />
              Download Template
            </button>
          </div>
          {questionnaires?.length > 0 && (
            <div className="doc-list-holder rounded rounded-xl shadow border border-gray-100">
              <div className="doc-list-header py-6 pl-8 pr-5 rounded rounded-xl bg-white flex items-center">
                <h2 className="font-normal text-gray-900 mb-0 grow">
                  Questionnaires List
                </h2>
                <div {...getRootProps()} className="hover:cursor-pointer">
                  <input {...getInputProps()} />
                  <div className="text-sm text-center">
                    <div className="p-2 my-2 inline-flex items-center rounded rounded-md">
                      <span className="group relative">
                        <div className="absolute bottom-[calc(100%+0.5rem)] left-[50%] -translate-x-[50%] hidden group-hover:block w-auto">
                          <div className="bottom-full right-0 rounded bg-gray-600 w-24 px-4 py-1 text-xs text-white">
                            Add another questioinnaire
                          </div>
                        </div>
                        <span>
                          <abbr className="text-xl font-bold cursor-pointer">
                            <img
                              src="/icons/add.png"
                              className="w-6 h-6"
                              alt="add"
                            />
                          </abbr>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <table className="min-w-full text-[15px]">
                <thead>
                  <tr>
                    <th className="w-2/3 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                      Name
                    </th>
                    <th className="flex items-center w-full py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                      <div className="flex-grow">Completion Percentage</div>

                      <span className="group relative">
                        <div className="absolute bottom-[calc(100%+0.5rem)] left-[50%] -translate-x-[50%] hidden group-hover:block w-auto">
                          <div className="bottom-full right-0 rounded bg-gray-600 w-48 px-4 py-1 text-xs text-white">
                            Document completion counts exclude questions where
                            EstiWiz couldn't find sufficient information to
                            provide an answer
                          </div>
                        </div>
                        <span>
                          <abbr className="text-xl font-bold cursor-pointer">
                            ℹ
                          </abbr>
                        </span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questionnaires.map((doc, index) => (
                    <tr
                      key={index}
                      className="group cursor-pointer border-t border-gray-200 first:border-t-0 relative text-gray-700 hover:bg-brandSubtle hover:text-brand even:bg-gray-10"
                      onClick={() => handleRowClick(doc.id)}
                    >
                      <td className="py-4 pl-8 group-last:rounded-0 group-last:rounded-bl-xl">
                        <div className="group relative min-w-full min-h-full">
                          {editingId === doc.id ? (
                            <div className="relative">
                              <input
                                type="text"
                                value={editingTitle}
                                onChange={(e) =>
                                  setEditingTitle(e.target.value)
                                }
                                autoFocus
                                className="block w-full py-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50"
                                onKeyUp={onEnterPress}
                              />
                              <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                <AiOutlineCheck
                                  onClick={sendUpdateQuestionnaire}
                                  size={20}
                                  className="hover:text-green-500 cursor-pointer"
                                />
                                <AiOutlineClose
                                  onClick={resetEditModel}
                                  size={20}
                                  className="hover:text-red-500 cursor-pointer"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          {deletingId === doc.id ? (
                            <div className="">
                              <div className="flex items-center gap-4">
                                <img
                                  src={getFileType(doc.title)}
                                  className="w-8"
                                  alt=""
                                />
                                {doc.title}
                              </div>

                              <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                <span className="text-sm mr-2">
                                  Are you sure?
                                </span>
                                <AiOutlineCheck
                                  className="hover:text-green-500 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleConfirmDelete(doc.id);
                                  }}
                                  size={20}
                                />
                                <AiOutlineClose
                                  className="hover:text-red-500 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCancelDelete();
                                  }}
                                  size={20}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              {editingId !== doc.id ? (
                                <div className="w-full">
                                  <div className="flex items-center gap-4 pr-16">
                                    <img
                                      src={getFileType(doc.title)}
                                      className="w-8"
                                      alt=""
                                    />
                                    {doc.title}
                                  </div>
                                  <AiOutlineEdit
                                    className="hover:text-gray-500 absolute right-10 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      stratEditing(doc);
                                    }}
                                  />
                                  <AiOutlineDelete
                                    className="hover:text-red-500 absolute right-4 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteClick(doc.id);
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="py-2 px-8 group-last:rounded-0 group-last:rounded-br-xl">
                        <div className="relative flex items-center gap-2">
                          <div className="w-full h-2 bg-gray-200 rounded-full">
                            <div
                              className={`h-2 ${getProgressColor(
                                doc.completion!
                              )} rounded-full`}
                              style={{ width: `${doc.completion!}%` }}
                            ></div>
                          </div>
                          <div className="text-sm font-semibold text-gray-500">
                            {Math.floor(doc.completion!)}%
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {questionnaires?.length === 0 && (
            <div>
              <div className="subtitle my-4">
                <h2>Upload a Questionnaire to start responding</h2>
                <p className="text-[14px] text-gray-600">
                  Please upload a XLSX file in the right format. You can
                  download the template by clicking the button above.
                </p>
              </div>
              <div className="my-6">
                <div {...getRootProps()} className="upload">
                  <input {...getInputProps()} />
                  <div className="text-sm text-center">
                    <div className="p-2 my-2 shadow inline-flex items-center border border-gray-200 rounded rounded-md">
                      <img
                        src="/icons/upload.png"
                        className="w-5 h-5"
                        alt="upload"
                      />
                    </div>
                    <br />
                    <span className="text-brand underline font-bold">
                      Click to upload
                    </span>{" "}
                    or drag & drop XLSX file here
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default Questionnaires;
