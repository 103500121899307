import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Project, Requirement } from "../store/project/types";
import { RootState } from "../store";
import StatusFlag from "./utils/StatusFlag";
import { useSelector } from "react-redux";
import {
  CurrentProposalSettings,
  CurrentQuote,
} from "../store/project/projectSlice";
import { formatNumber } from "./utils/helperFunctions";
import { logEvent } from "../utils";

const getResourceStatus = (requirement: Requirement) => {
  if (requirement.isResourcesApproved) {
    return "approved";
  }
  if (requirement.isWorkBreakdownApproved) {
    return "in_approval";
  }
  return "not_started";
};

const getQuoteStatus = (requirement: Requirement) => {
  if (requirement.isQuoteApproved) {
    return "approved";
  }
  if (requirement.isWorkBreakdownApproved && requirement.isResourcesApproved) {
    return "in_approval";
  }

  return "not_started";
};

const getProposalStatus = (requirement: Requirement) => {
  if (
    requirement.isWorkBreakdownApproved &&
    requirement.isResourcesApproved &&
    requirement.isQuoteApproved
  ) {
    return "available";
  }

  return "not_started";
};

export const DashboardBusinessOverview = ({
  project,
}: {
  project: Project | null;
}) => {
  const location = useLocation();
  const currentQuote = useSelector<RootState, CurrentQuote | null>(
    (state) => state.projectData.currentQuote
  );

  const proposalSettings = useSelector<
    RootState,
    CurrentProposalSettings | null
  >((state) => state.projectData.proposalSettings);
  if (!project?.requirement) {
    return null;
  }

  const trackLinkClick = (to: string) => {
    logEvent("Dashboard Card Click", {
      to,
    });
  };

  return (
    <div className="grid grid-cols-3 gap-6 w-full p-6 shadow border border-gray-200 rounded rounded-xl bg-brandYellow">
      <Link
        className={`text-brand font-bold ${
          getQuoteStatus(project.requirement) === "not_started"
            ? "text-gray-400 cursor-not-allowed"
            : ""
        }`}
        to={
          getQuoteStatus(project.requirement) !== "not_started"
            ? `quote/${project.requirement.id}`
            : location.pathname
        }
        onClick={() => trackLinkClick("Quote")}
      >
        <div className="border border-gray-200 rounded rounded-xl">
          <div
            className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
              getQuoteStatus(project.requirement) === "not_started"
                ? "hover:bg-white"
                : ""
            }`}
          >
            <div className="pt-4 pl-6 pr-6 grow">
              <h3 className="font-bold text-gray-700 mb-0">Quote</h3>
              <p className="text-sm font-normal text-gray-500 my-2">
                View quote, adjust margins and discounts
              </p>
              <div className="flex justify-between items-center my-8">
                {getQuoteStatus(project.requirement) !== "not_started" ? (
                  <div className="flex flex-col">
                    <span className="text-gray-700 text-4xl font-bold">
                      {currentQuote &&
                      currentQuote.quote &&
                      currentQuote?.quote?.quoteTotals[0] &&
                      currentQuote?.quote?.quoteTotals[0].netMarginAmount
                        ? "$" +
                          formatNumber(
                            currentQuote?.quote?.quoteTotals[0].netMarginAmount
                          )
                        : "0"}
                    </span>
                    <span className="text-sm text-gray-400">Net margin</span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <span className="text-gray-700 text-4xl font-bold">
                      &nbsp;
                    </span>
                    <span className="text-sm text-gray-400">&nbsp;</span>
                  </div>
                )}

                <StatusFlag status={getQuoteStatus(project.requirement)} />
              </div>
            </div>
            <div
              className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                getResourceStatus(project.requirement) === "not_started" ||
                getResourceStatus(project.requirement) === "in_approval"
                  ? "text-gray-400 opacity-50"
                  : ""
              }`}
            >
              <img
                src="/icons/view.png"
                className={`w-5 ${
                  getResourceStatus(project.requirement) === "not_started" ||
                  getResourceStatus(project.requirement) === "in_approval"
                    ? "opacity-30"
                    : ""
                } `}
                alt=""
              />
              <span>View</span>
            </div>
          </div>
        </div>
      </Link>
      {/******************* Proposal ************************/}

      <Link
        className={`text-brand font-bold ${
          getProposalStatus(project.requirement) === "not_started"
            ? "text-gray-400 cursor-not-allowed"
            : ""
        }`}
        to={
          getProposalStatus(project.requirement) !== "not_started"
            ? `proposal/${project.requirement.id}`
            : location.pathname
        }
        onClick={() => trackLinkClick("Proposal from dashboard clicked")}
      >
        <div className="border border-gray-200 rounded rounded-xl">
          <div
            className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
              getQuoteStatus(project.requirement) === "not_started" ||
              getQuoteStatus(project.requirement) === "in_approval"
                ? "hover:bg-white"
                : ""
            }`}
          >
            <div className="pt-4 pl-6 pr-6 grow">
              <h3 className="font-bold text-gray-700 mb-0">Proposal</h3>
              <p className="text-sm font-normal text-gray-500 my-2">
                Generate your proposal
              </p>
              <div className="flex justify-between items-center my-8">
                <div className="flex flex-col">
                  <span className="text-gray-700 text-4xl font-bold">
                    {proposalSettings?.activeDocument?.sections.filter(
                      (section) => section.selected
                    ).length
                      ? proposalSettings?.activeDocument?.sections.filter(
                          (section) => section.selected
                        ).length
                      : ""}
                    &nbsp;
                  </span>
                  {getProposalStatus(project.requirement) === "available" &&
                  proposalSettings?.activeDocument?.sections.filter(
                    (section) => section.selected
                  ).length ? (
                    <span className="text-sm text-gray-400">
                      section(s) added
                    </span>
                  ) : (
                    <span className="text-sm text-gray-400">&nbsp;</span>
                  )}
                </div>
                <StatusFlag status={getProposalStatus(project.requirement)} />
              </div>
            </div>
            <div
              className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                getQuoteStatus(project.requirement) === "not_started" ||
                getQuoteStatus(project.requirement) === "in_approval"
                  ? "text-gray-400 opacity-50"
                  : ""
              }`}
            >
              <img
                src="/icons/view.png"
                className={`w-5 ${
                  getQuoteStatus(project.requirement) === "not_started" ||
                  getQuoteStatus(project.requirement) === "in_approval"
                    ? "opacity-30"
                    : ""
                } `}
                alt=""
              />
              <span>View</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
