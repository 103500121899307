import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Project,
  RequirementPhase,
  RequirementTask,
  ResourceType,
} from "../../store/project/types";
import {
  useGetProjectPhaseTasksAndSubTasksQuery,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useCreateTaskMutation,
} from "../../store/project/projectApiService";
import {
  setCurrentPhase,
} from "../../store/project/projectSlice";
import { TableLoader } from "../utils/ContentLoader";
import { logEvent } from "../../utils";
import TaskEditDrawer from "./TaskEditDrawer";
import { toast } from "react-toastify";
import { useGetResourceTypesQuery } from "../../store/resourceType/resourceTypeApiService";
import { setResourceTypes } from "../../store/resourceType/resourceTypeSlice";
import { Tooltip } from "react-tooltip";
import { Company } from "../../store/company/types";

interface PhaseDetailsProps {
  requirementId: string;
  phase: RequirementPhase;
  isOpen: boolean;
  project: Project | null;
}

const PhaseDetails: React.FC<PhaseDetailsProps> = ({
  requirementId,
  phase,
  isOpen,
  project,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showMask, setShowMask] = useState(false);
  const [editingTask, setEditingTask] = useState<RequirementTask | null>(null);
  const [resourcesTotal, setResourcesTotal] = useState<
    { resourceType: string; effort: number }[]
  >([]);
  const [saveEditDelete, setSaveEditDelete] = useState(false);
  const dispatch = useDispatch();
  const [createTask] = useCreateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();
  const [updateTask] = useUpdateTaskMutation();

  const { data: requestPhaseData } = useGetProjectPhaseTasksAndSubTasksQuery(
    phase.id,
    {
      skip: !isOpen,
    }
  );

  const resourceTypes = useSelector<RootState, ResourceType[]>(
    (state) => state.resourceTypeData.resourceTypes
  );

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );

  const { data: resourceTypesData } = useGetResourceTypesQuery();

  const requirementPhase = useSelector<RootState, RequirementPhase | null>(
    (state) => {
      return state.projectData.currentPhase;
    }
  );

  useEffect(() => {
    if (saveEditDelete) {
      const total = aggregateResources(
        requirementPhase || ({} as RequirementPhase)
      );
      setResourcesTotal(total);
      setSaveEditDelete(false);
    }
  }, [requirementPhase]);

  useEffect(() => {
    if (isOpen && requestPhaseData) {
      dispatch(setCurrentPhase(requestPhaseData.data));
      const total = aggregateResources(requestPhaseData.data);
      setResourcesTotal(total);
    }
  }, [isOpen, requestPhaseData, dispatch]);

  useEffect(() => {
    if (resourceTypesData) {
      dispatch(setResourceTypes(resourceTypesData.data));
    }
  }, [resourceTypesData, dispatch]);

  const editTask = (task: RequirementTask) => {
    if (project?.requirement?.isWorkBreakdownApproved) return;
    logEvent("Edit Task", { company: company?.name });
    setEditingTask(task);
    setDrawerSequence(true);
  };

  const setDrawerSequence = (open: boolean) => {
    if (!open) {
      setTimeout(() => {
        setShowMask(open);
      });
      setTimeout(() => {
        setIsEditing(open);
      }, 300);
    } else {
      setTimeout(() => {
        setIsEditing(open);
      });
      setTimeout(() => {
        setShowMask(open);
      }, 300);
    }
  };

  const handleConfirmDelete = async (item: RequirementTask) => {
    try {
      setSaveEditDelete(true);
      if (item.parentId) {
        const parentTask: RequirementTask | undefined =
          requirementPhase?.tasks.find((t) => t.id === item.parentId);
        if (parentTask) {
          const updatedSubTasks = parentTask.subTasks.filter(
            (t) => t.id !== item.id
          );
          const updatedTasks = requirementPhase?.tasks.map((t) =>
            t.id === parentTask.id
              ? { ...parentTask, subTasks: updatedSubTasks }
              : t
          );
          dispatch(
            setCurrentPhase({ ...requirementPhase, tasks: updatedTasks })
          );
        } else {
          toast.error(
            "Something went wrong! Please try again. RefID: TaskNotFound__item.parentId "
          );
        }
      } else {
        const updatedTasks = requirementPhase?.tasks.filter(
          (t) => t.id !== item.id
        );
        dispatch(setCurrentPhase({ ...requirementPhase, tasks: updatedTasks }));
      }
      await deleteTask({
        requirementId,
        id: item.id,
      }).unwrap();
      toast.success("Successfully deleted!");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting item!");
    }
  };

  const addNewTask = (parentId?: string): void => {
    if (project?.requirement?.isWorkBreakdownApproved) return;
    logEvent("Add New Task", { company: company?.name });
    const newTask = {
      name: "[New Task]",
      description: "",
      parentId: parentId || undefined,
      resources: [],
      subTasks: [],
      phaseId: phase.id,
    };
    createTask({
      phaseId: requirementPhase!.id,
      data: newTask,
    })
      .unwrap()
      .then((result) => {
        const createdItem = !parentId
          ? result.data.tasks.find(
              (item: RequirementTask) => item.name === "[New Task]"
            )
          : result.data.tasks.reduce<RequirementTask | null>((found, task) => {
              if (found) return found; // If already found, return it
              return (
                task.subTasks.find(
                  (subtask: RequirementTask) => subtask.name === "[New Task]"
                ) || null
              );
            }, null);

        if (createdItem) {
          dispatch(
            setCurrentPhase({
              ...requirementPhase,
              tasks: result.data.tasks,
            })
          );
          toast.success(`Successfully created new task!`);

          setEditingTask({
            id: createdItem.id,
            name: createdItem.name,
            description: createdItem.description,
            subTasks: createdItem.subTasks,
            resources: createdItem.resources,
            parentId: createdItem.parentId,
            isConfirmed: createdItem.isConfirmed,
            isResourcesGenerated: createdItem.isResourcesGenerated,
          });
          setDrawerSequence(true);
        } else {
          toast.error("Could not find the newly created item.");
        }
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Error creating new task");
      });
  };

  const handleSaveEdit = async (task: RequirementTask) => {
    try {
      setSaveEditDelete(true);

      if (task.parentId) {
        const parentTask: RequirementTask | undefined =
          requirementPhase?.tasks.find((t) => t.id === task.parentId);
        if (parentTask) {
          const updatedSubTasks = parentTask.subTasks.map((t) =>
            t.id === task.id ? task : t
          );
          const updatedTasks = requirementPhase?.tasks.map((t) =>
            t.id === parentTask.id
              ? { ...parentTask, subTasks: updatedSubTasks }
              : t
          );
          dispatch(
            setCurrentPhase({
              ...requirementPhase,
              tasks: updatedTasks,
            })
          );
        }
      } else {
        const updatedTasks = requirementPhase?.tasks.map((t) =>
          t.id === task.id ? task : t
        );
        dispatch(
          setCurrentPhase({
            ...requirementPhase,
            tasks: updatedTasks,
          })
        );
      }
      await updateTask(task).unwrap();
      toast.success("Successfully updated!");
      setDrawerSequence(false);
      setEditingTask(null);
    } catch (err: any) {
      toast.error(err?.data?.message || "Error updating item!");
    }
  };

  const loaded = () => {
    return requestPhaseData && requestPhaseData.data.id === phase.id;
  };

  const aggregateResources = (
    currentPhase: RequirementPhase
  ): { resourceType: string; effort: number }[] => {
    const resourceMap: Map<string, number> = new Map();
    let total = 0;

    currentPhase?.tasks.forEach((task) => {
      task?.subTasks.forEach((subtask) => {
        subtask.resources.forEach((resource) => {
          const { resourceType, effort } = resource;
          const resourceName = resourceType.name;
          const parsedEffort = parseInt(effort.toString());

          if (!isNaN(parsedEffort)) {
            if (resourceMap.has(resourceName)) {
              const currentValue = resourceMap.get(resourceName) || 0;
              resourceMap.set(resourceName, currentValue + parsedEffort);
            } else {
              resourceMap.set(resourceName, parsedEffort);
            }
          } else {
            console.warn(
              `Invalid effort value for resource '${resourceName}': '${effort}'. Skipping.`
            );
          }
          total = total + parsedEffort;
        });
      });
    });

    const aggregatedResources: { resourceType: string; effort: number }[] = [];
    resourceMap.forEach((effort, resourceType) => {
      aggregatedResources.push({ resourceType, effort });
    });

    aggregatedResources.push({ resourceType: "TOTAL", effort: total });

    return aggregatedResources;
  };

  return (
    <div className={`collapsible-content ${isOpen ? "expanded" : ""} w-full`}>
      {isOpen && (
        <div className="container">
          <div>
            <table className="min-w-full text-[15px]">
              <thead>
                <tr>
                  <th className="w-1/4 py-3 px-8 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                    Task/Subtask
                    <div className="inline-flex gap-2 items-center rounded-md cursor-pointer group ml-2">
                      <span
                        data-tooltip-id="taskSubtaskInfo"
                        data-tooltip-content="Click on the row to edit/delete task or subtask"
                      >
                        ℹ
                      </span>
                      <Tooltip id="taskSubtaskInfo" />
                    </div>
                  </th>
                  <th className="w-1/2 py-3 px-5 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                    Description
                  </th>
                  <th className="w-1/5 py-3 px-2 border-b border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                    <div className="flex items-center justify-between pr-6">
                      <span>Resources (h)</span>
                      {!project?.requirement?.isWorkBreakdownApproved && (
                        <div>
                          <button
                            className="button-secondary !bg-white"
                            data-tooltip-id={`addNewTask-${phase.id}`}
                            data-tooltip-content="Add New Task"
                            onClick={() => addNewTask()}
                          >
                            <img src="/icons/add.png" className="w-6" alt="" />
                          </button>
                          <Tooltip id={`addNewTask-${phase.id}`} />
                        </div>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!loaded() ? (
                  <tr>
                    <td colSpan={4}>
                      <TableLoader />
                    </td>
                  </tr>
                ) : requirementPhase?.tasks?.length === 0 ? (
                  <tr>
                    <td colSpan={4}>
                      <div className="py-3 px-8 font-bold text-xl">
                        No tasks yet
                      </div>
                    </td>
                  </tr>
                ) : (
                  requirementPhase?.tasks.map((task, taskIndex) => (
                    <tr key={task.id}>
                      <td className="m-0 p-0" colSpan={4}>
                        <table className="w-full">
                          <tbody>
                            <tr
                              className={`group/task ${
                                project?.requirement?.isWorkBreakdownApproved
                                  ? "cursor-not-allowed"
                                  : "cursor-pointer"
                              } w-full hover:bg-brandSubtle hover:text-brand`}
                              onClick={() => editTask(task)}
                            >
                              <td
                                className="py-6 pl-8 border border-r-0 border-l-0 border-b-0"
                                colSpan={3}
                              >
                                <div className="flex gap-8 pr-8 font-semibold text-lg text-[#475467]">
                                  <span>&nbsp;{taskIndex + 1}</span>
                                  <span className="grow uppercase">
                                    {task.name}
                                  </span>
                                  {!project?.requirement
                                    ?.isWorkBreakdownApproved && (
                                    <div>
                                      <button
                                        className="button-secondary !bg-white text-normal"
                                        data-tooltip-id={`addNewSubTask-${task.id}`}
                                        data-tooltip-content="Add New Sub-Task"
                                        onClick={() => addNewTask(task.id)}
                                      >
                                        <img
                                          src="/icons/add.png"
                                          className="w-6"
                                          alt=""
                                        />
                                      </button>
                                      <Tooltip
                                        id={`addNewSubTask-${task.id}`}
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                            {task.subTasks.length === 0 ? (
                              <tr key={`subtask-${task.id}`}>
                                <td
                                  colSpan={3}
                                  className="py-3 px-8 text-normal text-gray-400 text-center"
                                >
                                  Generiting subtasks...
                                </td>
                              </tr>
                            ) : (
                              task.subTasks.map((subtask, subtaskIndex) => (
                                <tr
                                  onClick={() => editTask(subtask)}
                                  key={subtask.id}
                                  className={`group/subtask even:bg-gray-25 hover:bg-brandSubtle hover:text-brand ${
                                    project?.requirement
                                      ?.isWorkBreakdownApproved
                                      ? "cursor-not-allowed"
                                      : "cursor-pointer"
                                  }`}
                                >
                                  <td className="w-1/4 py-4 pl-8 h-full">
                                    <div className="flex gap-7">
                                      <span>
                                        {taskIndex + 1}.{subtaskIndex + 1}
                                      </span>
                                      <span>{subtask.name}</span>
                                    </div>
                                  </td>
                                  <td className="w-1/2 py-4 pl-6 h-full">
                                    {subtask.description}
                                  </td>
                                  <td className="w-1/5 py-4 pl-2 pr-4 h-full">
                                    <div className="min-w-full">
                                      <table className="w-full">
                                        <tbody>
                                          {subtask.resources.map(
                                            (resource, index) => (
                                              <div
                                                key={index}
                                                className="flex gap-6 w-full pr-2"
                                              >
                                                <span className="justify-self-start w-3/4">
                                                  {resource.resourceType.name}
                                                </span>
                                                <span className="justify-self-end w-1/4 text-right">
                                                  {resource.effort}
                                                </span>
                                              </div>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full grid grid-cols-2 justify-items-end content-between pl-14 pr-6 py-8 bg-brandYellow rounded-0 rounded-br-xl rounded-bl-xl border-t">
            <span className="font-bold text-[#475467] justify-self-start">
              RESOURCES SUBTOTAL
            </span>
            <div className="w-56 text-[0.9rem] flex flex-col gap-2">
              {resourcesTotal.map((resource, index) => (
                <div
                  key={index}
                  className="flex gap-6 w-full font-semibold text-[#475467]"
                >
                  <span className="justify-self-start w-3/4">
                    {resource.resourceType}
                  </span>
                  <span className="justify-self-end w-1/4 text-right">
                    {resource.effort}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <TaskEditDrawer
        isOpen={isEditing}
        maskOpen={showMask}
        toggleDrawer={() => setDrawerSequence(false)}
        task={editingTask}
        onSave={handleSaveEdit}
        onDelete={handleConfirmDelete}
        resourceTypes={resourceTypes}
      />
    </div>
  );
};

export default PhaseDetails;
