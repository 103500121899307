import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Project,
  ProjectStage,
  RequiredResourcesSummary,
  RequirementAssumption,
  RequirementPhase,
  RequirementPrerequisite,
  RequirementQuestion,
  RequirementTask,
  ResourceRequirement,
  ScopeItem,
} from "./types";
import { getAccessToken } from "../../utils";
import { Questionnaire } from "../questionnaire/types";
import { Quote } from "../quote/types";
import { ProposalDocumentSection } from "../proposal/types";
import { ProposalDocument } from "./projectSlice";

const api = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/project",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: [
    "Project",
    "Projects",
    "Phase",
    "ScopeContext",
    "QuestionsAndAssumptions",
    "HighLevelTasks",
    "TaskAndSubTasks",
    "ResourceSummary",
    "Quote",
    "ProposalSettings",
  ],
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => "/",
      providesTags: [{ type: "Projects" }],
    }),
    getProjectById: builder.query<
      {
        data: Project;
      },
      string
    >({
      query: (id: string) => `${id}`,
      providesTags: (_result, _error, id) => [{ type: "Project", id }],
    }),
    getQuestionnairesByProjectId: builder.query<
      {
        data: Questionnaire[];
      },
      string
    >({
      query: (id: string) => `questionnaires/${id}`,
    }),
    getScopeContext: builder.query<
      {
        data: {
          items: ScopeItem[];
          isScopeGenerated: boolean;
        };
        message: string;
      },
      string
    >({
      query: (requirementId: string) => `scope-context/${requirementId}`,
      providesTags: (_result, _error, requirementId) => [
        { type: "ScopeContext", id: requirementId },
      ],
    }),
    deleteScopeItem: builder.mutation<
      {
        data: ScopeItem[];
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `scope-item/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "ScopeContext" }, { type: "Project" }],
    }),
    updateScopeItem: builder.mutation<
      {
        data: ScopeItem[];
        message: string;
      },
      Partial<ScopeItem>
    >({
      query: (scopeItem) => {
        return {
          url: `scope-item/${scopeItem.id!}`,
          method: "PATCH",
          body: scopeItem,
        };
      },
      invalidatesTags: [{ type: "ScopeContext" }, { type: "Project" }],
    }),
    createScopeItem: builder.mutation<
      {
        data: ScopeItem[];
        message: string;
      },
      {
        requirementId: string;
        data: Partial<ScopeItem>;
      }
    >({
      query: ({ requirementId, data }) => {
        return {
          url: `scope-item/${requirementId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "ScopeContext" }, { type: "Project" }],
    }),
    getQuestionsAndAssumptions: builder.query<
      {
        data: {
          questions: RequirementQuestion[];
          assumptions: RequirementAssumption[];
          prerequisites: RequirementAssumption[];
          isAssumptionsGenerated: boolean;
          isPrerequisiteGenerated: boolean;
          isQuestionsGenerated: boolean;
        };
        message: string;
      },
      string
    >({
      query: (requirementId: string) =>
        `questions-and-assumptions/${requirementId}`,
      providesTags: (_result, _error, requirementId) => [
        { type: "QuestionsAndAssumptions", id: requirementId },
      ],
    }),
    deleteQuestion: builder.mutation<
      {
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `question/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),
    updateQuestion: builder.mutation<
      {
        message: string;
      },
      Partial<RequirementQuestion>
    >({
      query: (question) => {
        return {
          url: `question/${question.id!}`,
          method: "PATCH",
          body: question,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),
    createQuestion: builder.mutation<
      {
        data: RequirementQuestion[];
        message: string;
      },
      {
        requirementId: string;
        data: Partial<RequirementQuestion>;
      }
    >({
      query: ({ requirementId, data }) => {
        return {
          url: `question/${requirementId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),
    createAssumption: builder.mutation<
      {
        data: RequirementAssumption[];
        message: string;
      },
      {
        requirementId: string;
        data: Partial<RequirementAssumption>;
      }
    >({
      query: ({ requirementId, data }) => {
        return {
          url: `assumption/${requirementId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),
    deleteAssumption: builder.mutation<
      {
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `assumption/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),

    updateAssumption: builder.mutation<
      {
        message: string;
      },
      Partial<RequirementAssumption>
    >({
      query: (assumption) => {
        return {
          url: `assumption/${assumption.id!}`,
          method: "PATCH",
          body: assumption,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),

    deletePrerequisite: builder.mutation<
      {
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `prerequisite/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),

    updatePrerequisite: builder.mutation<
      {
        message: string;
      },
      Partial<RequirementPrerequisite>
    >({
      query: (prerequisite) => {
        return {
          url: `prerequisite/${prerequisite.id!}`,
          method: "PATCH",
          body: prerequisite,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),

    createPrerequisite: builder.mutation<
      {
        data: RequirementPrerequisite[];
        message: string;
      },
      {
        requirementId: string;
        data: Partial<RequirementPrerequisite>;
      }
    >({
      query: ({ requirementId, data }) => {
        return {
          url: `prerequisite/${requirementId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "QuestionsAndAssumptions" }],
    }),

    getHighLevelTasks: builder.query<
      {
        data: {
          phases: RequirementPhase[];
        };
        message: string;
      },
      string
    >({
      query: (requirementId: string) => `high-level-tasks/${requirementId}`,
      providesTags: (_result, _error, requirementId) => [
        { type: "HighLevelTasks", id: requirementId },
      ],
    }),

    createTask: builder.mutation<
      {
        data: {
          tasks: RequirementTask[];
        };
        message: string;
      },
      {
        phaseId: string;
        data: Partial<RequirementTask>;
      }
    >({
      query: ({ phaseId, data }) => {
        return {
          url: `task/${phaseId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [
        { type: "TaskAndSubTasks" },
        { type: "HighLevelTasks" },
      ],
    }),

    deleteTask: builder.mutation<
      {
        message: string;
      },
      {
        requirementId: string;
        id: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `task/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "TaskAndSubTasks" },
        { type: "HighLevelTasks" },
        { type: "ProposalSettings", id: requirementId },
      ],
    }),

    updateTask: builder.mutation<
      {
        message: string;
      },
      Partial<RequirementTask>
    >({
      query: (task) => {
        return {
          url: `task/${task.id!}`,
          method: "PATCH",
          body: task,
        };
      },
      invalidatesTags: [
        { type: "TaskAndSubTasks" },
        { type: "HighLevelTasks" },
      ],
    }),

    deletePhase: builder.mutation<
      {
        data: {
          phases: RequirementPhase[];
        };
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `phase/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "HighLevelTasks" }],
    }),

    updatePhase: builder.mutation<
      {
        data: {
          phases: RequirementPhase[];
        };
        message: string;
      },
      Partial<RequirementPhase>
    >({
      query: (phase) => {
        return {
          url: `phase/${phase.id!}`,
          method: "PATCH",
          body: phase,
        };
      },
      invalidatesTags: [{ type: "HighLevelTasks" }],
    }),

    updatePhaseRanks: builder.mutation<
      {
        data: RequirementPhase[];
        message: string;
      },
      {
        requirementId: string;
        phases: RequirementPhase[];
      }
    >({
      query: ({ requirementId, phases }) => {
        return {
          url: `phase/update-ranks/${requirementId}`,
          method: "PATCH",
          body: { phases },
        };
      },
      invalidatesTags: [{ type: "HighLevelTasks" }],
    }),

    createPhase: builder.mutation<
      {
        data: {
          phases: RequirementPhase[];
        };
        message: string;
      },
      {
        requirementId: string;
        data: Partial<RequirementPhase>;
      }
    >({
      query: ({ requirementId, data }) => {
        return {
          url: `phase/${requirementId}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "HighLevelTasks" }],
    }),

    getProjectPhaseTasksAndSubTasks: builder.query<
      {
        data: RequirementPhase;
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `phase/${id}`,
          method: "GET",
        };
      },
      providesTags: (_result, _error, id) => [{ type: "TaskAndSubTasks", id }],
    }),

    getRequestResourceRequirements: builder.query<
      {
        data: RequiredResourcesSummary;
        message: string;
      },
      string
    >({
      query: (requirementId: string) => {
        return {
          url: `resource-summary/${requirementId}`,
          method: "GET",
        };
      },
      providesTags: (_result, _error, requirementId) => [
        { type: "ResourceSummary", id: requirementId },
      ],
    }),
    deleteResource: builder.mutation<
      {
        data: RequiredResourcesSummary;
        message: string;
      },
      string
    >({
      query: (resourceId: string) => {
        return {
          url: `resource/${resourceId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Phase" }, { type: "Project" }],
    }),
    getPhaseResourceTypeResources: builder.query<
      {
        data: RequiredResourcesSummary;
        message: string;
      },
      {
        resourceTypeId: string;
        phaseId: string;
      }
    >({
      query: ({ resourceTypeId, phaseId }) => {
        return {
          url: `phase-resource/${phaseId}/${resourceTypeId}`,
          method: "GET",
        };
      },
      providesTags: [{ type: "Phase" }],
    }),

    updateRequestResourceRequirements: builder.mutation<
      {
        message: string;
      },
      Partial<ResourceRequirement>
    >({
      query: (data) => {
        return {
          url: `resource/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Phase" }, { type: "Project" }],
    }),
    updateRequestResourceRequirementsByResourceType: builder.mutation<
      {
        message: string;
      },
      {
        resourceTypeId: string;
        data: Partial<ResourceRequirement>;
      }
    >({
      query: ({ resourceTypeId, data }) => {
        return {
          url: `resources-by-resource-type/${resourceTypeId}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: [{ type: "Phase" }, { type: "Project" }],
    }),
    updateProject: builder.mutation<Project, Project>({
      query: (project: Project) => {
        const { id, ...rest } = project;
        return {
          url: `/${project.id}`,
          method: "PATCH",
          body: rest,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
    approveStage: builder.mutation<
      {
        data: Project;
        message: string;
      },
      { requirementId: string; stage: ProjectStage }
    >({
      query: ({ requirementId, stage }) => {
        return {
          url: `approve-stage/${requirementId}`,
          method: "post",
          body: { stage },
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "Project" },
        { type: "HighLevelTasks" },
        { type: "Phase" },
        { type: "ProposalSettings", id: requirementId },
        { type: "ResourceSummary", id: requirementId },
      ],
    }),
    uploadProjectRequirements: builder.mutation<
      {
        data: Project;
        message: string;
      },
      { projectId: string; file: File }
    >({
      query: ({ projectId, file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `/requirement/${projectId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
    regenerateScope: builder.mutation<
      {
        data: Project;
        message: string;
      },
      string
    >({
      query: (requirementId: string) => {
        return {
          url: `requirement/regenerate-scope/${requirementId}`,
          method: "POST",
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),
    updateScopeItems: builder.mutation<
      { data: ScopeItem[]; message: string },
      { requirementId: string; scopeItems: Partial<ScopeItem>[] }
    >({
      query: ({ requirementId, scopeItems }) => {
        return {
          url: `requirement/update-scope-items/${requirementId}`,
          method: "PATCH",
          body: { scopeItems },
        };
      },
      invalidatesTags: [{ type: "ScopeContext" }],
    }),
    deleteRequirement: builder.mutation<
      {
        message: string;
      },
      string
    >({
      query: (id: string) => {
        return {
          url: `/requirement/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Project" }],
    }),

    createProject: builder.mutation<
      {
        data: Project;
        message: string;
      },
      {
        title: string;
      }
    >({
      query: ({ title }) => ({
        url: "",
        method: "POST",
        body: {
          title,
        },
      }),
      invalidatesTags: [{ type: "Projects" }],
    }),
    deleteProject: builder.mutation<null, string>({
      query: (id: string) => {
        return {
          url: "",
          method: "DELETE",
          body: {
            id,
          },
        };
      },
      invalidatesTags: [{ type: "Projects" }],
    }),
    getQuote: builder.query<
      {
        data: Quote;
      },
      string
    >({
      query: (requirementId: string) => `quote/${requirementId}`,
      providesTags: (_result, _error, requirementId) => [
        { type: "Quote", id: requirementId },
      ],
    }),
    updateQuote: builder.mutation<
      {
        message: string;
      },
      Quote
    >({
      query: (quote) => {
        return {
          url: `quote/${quote.id!}`,
          method: "PATCH",
          body: quote,
        };
      },
    }),

    getProposalSettings: builder.query<
      {
        data: {
          allDocuments: ProposalDocument[] | null;
          activeDocument: ProposalDocument | null;
        };
      },
      string
    >({
      query: (requirementId: string) => `proposal-settings/${requirementId}`,
      providesTags: (_result, _error, requirementId) => [
        { type: "ProposalSettings", id: requirementId },
      ],
    }),
    updateProposalSettings: builder.mutation<
      {
        data: {
          allDocuments: ProposalDocument[] | null;
          activeDocument: ProposalDocument | null;
        };
      },
      {
        id: string;
        requirementId: string;
        sections: ProposalDocumentSection[];
      }
    >({
      query: ({ id, sections }) => {
        return {
          url: `proposal-settings/${id}`,
          method: "PATCH",
          body: {
            sections,
          },
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "ProposalSettings", id: requirementId },
      ],
    }),
    newProposalDocument: builder.mutation<
      {
        data: {
          allDocuments: ProposalDocument[] | null;
          activeDocument: ProposalDocument | null;
        };
      },
      {
        id: string;
        requirementId: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `proposal-document/${id}`,
          method: "POST",
          body: {},
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "ProposalSettings", id: requirementId },
      ],
    }),
    setProposalDocumentActive: builder.mutation<
      {
        data: {
          allDocuments: ProposalDocument[] | null;
          activeDocument: ProposalDocument | null;
        };
      },
      {
        id: string;
        requirementId: string;
      }
    >({
      query: ({ id }) => {
        return {
          url: `proposal-document/${id}/set-active`,
          method: "PATCH",
          body: {},
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "ProposalSettings", id: requirementId },
      ],
    }),
    generateProposal: builder.mutation<
      {
        data: {
          allDocuments: ProposalDocument[] | null;
          activeDocument: ProposalDocument | null;
        };
      },
      {
        id: string;
        requirementId: string;
      }
    >({
      query: ({ id, requirementId }) => {
        return {
          url: `generate-proposal/${id}`,
          method: "POST",
          body: {
            requirementId,
          },
        };
      },
      invalidatesTags: (_result, _error, { requirementId }) => [
        { type: "ProposalSettings", id: requirementId },
      ],
    }),
    requirementHighLevelEstimation: builder.query<
      {
        data: {
          priceFrom: number;
          priceTo: number;
          effortFrom: number;
          effortTo: number;
          durationFrom: number;
          durationTo: number;
        };
      },
      string
    >({
      query: (requirementId: string) =>
        `requirement-high-level-estimation/${requirementId}`,
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useGetQuestionnairesByProjectIdQuery,
  useGetScopeContextQuery,
  useDeleteScopeItemMutation,
  useUpdateScopeItemMutation,
  useCreateScopeItemMutation,
  useGetQuestionsAndAssumptionsQuery,
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useUpdateQuestionMutation,
  useCreateAssumptionMutation,
  useDeleteAssumptionMutation,
  useUpdateAssumptionMutation,
  useCreatePrerequisiteMutation,
  useDeletePrerequisiteMutation,
  useUpdatePrerequisiteMutation,
  useGetProjectPhaseTasksAndSubTasksQuery,
  useCreateTaskMutation,
  useDeleteTaskMutation,
  useUpdateTaskMutation,
  useDeletePhaseMutation,
  useUpdatePhaseMutation,
  useCreatePhaseMutation,
  useUpdatePhaseRanksMutation,
  useGetHighLevelTasksQuery,
  useGetRequestResourceRequirementsQuery,
  useGetPhaseResourceTypeResourcesQuery,
  useDeleteResourceMutation,
  useUpdateRequestResourceRequirementsMutation,
  useUpdateRequestResourceRequirementsByResourceTypeMutation,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useUploadProjectRequirementsMutation,
  useRegenerateScopeMutation,
  useUpdateScopeItemsMutation,
  useDeleteRequirementMutation,
  useApproveStageMutation,
  useGetQuoteQuery,
  useUpdateQuoteMutation,
  useGetProposalSettingsQuery,
  useUpdateProposalSettingsMutation,
  useNewProposalDocumentMutation,
  useSetProposalDocumentActiveMutation,
  useGenerateProposalMutation,
  useRequirementHighLevelEstimationQuery,
  util: projectApiUtils,
} = api;

export const projectApiMiddleware = api.middleware;

export default api.reducer;

export const downloadProposal = async (
  id: string,
  name: string,
  type: string
) => {
  const response = await fetch(
    `/api/project/proposal/${id}/download?type=${type}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    }
  );
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${name}.${type === "pdf" ? "pdf" : "docx"}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
