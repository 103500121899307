import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetProjectsQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from "../store/project/projectApiService";
import { setCurrentProject, setProjects } from "../store/project/projectSlice";
import { RootState } from "../store";
import { Project } from "../store/project/types";
import { toast } from "react-toastify";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import NewOpportunity from "./NewOpportunity";
import { TableLoader } from "./utils/ContentLoader";
import { Tooltip } from "react-tooltip";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteProject] = useDeleteProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const { data, isSuccess } = useGetProjectsQuery();
  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingTitle, setEditingTitle] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const projects = useSelector<RootState, Project[] | null>((state) => {
    return state.projectData.projects;
  });

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setProjects(data));
    }
  }, [isSuccess, data, dispatch]);

  const resetEditModel = () => {
    setEditingId(null);
    setEditingTitle("");
  };

  const sendUpdateUprojectChanges = () => {
    if (editingId) {
      const editingItem = {
        id: editingId,
        title: editingTitle,
        summary: project?.summary || "",
      };
      const newItem = projects!.map((item) =>
        item.id === editingId ? { ...item, ...editingItem } : item
      );
      dispatch(setProjects(newItem));
      updateProject(editingItem)
        .unwrap()
        .then(() => {
          const newProjectList = projects!.map((item) => {
            if (item.id === editingId) {
              return editingItem;
            }
            return item;
          });
          dispatch(setProjects(newProjectList));
        });
    }
    resetEditModel();
  };
  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendUpdateUprojectChanges();
    } else if (e.key === "Escape") {
      resetEditModel();
    }
  };

  const stratEditing = (item: Project) => {
    setEditingId(item.id);
    setEditingTitle(item.title);
  };

  const handleRowClick = (id: string) => {
    if (!editingId && !deletingId) {
      navigate(`/dashboard/${id}`);
    }
  };

  if (!projects) return null;

  const handleDeleteClick = (docId: string) => {
    setDeletingId(docId);
  };

  const handleConfirmDelete = (id: string) => {
    deleteProject(id)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        setDeletingId("");
        if (project?.id === id) {
          dispatch(setCurrentProject(null));
        }
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  if (!isSuccess) return null;

  const loaded = () => {
    return (
      projects.length > 0
    );
  };

  return (
    <div className="p-4 mt-2">
      <div className="flex w-full mb-6">
        {projects?.length > 0 ? <h1 className="grow">Opportunities</h1> : null}
      </div>
      <NewOpportunity
        projects={projects}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
      />
      {projects?.length === 0 ? (
        <div className="h-[50vh] p-4 flex items-center justify-center">
          <div
            className="w-[500px] h-[650px] flex flex-col gap-4 items-center justify-center pt-36"
            style={{
              backgroundImage: `url('images/start-bg.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <h2 className="text-gray-900 mb-0">Let's get started!</h2>
            <p className="text-center text-gray-500 w-2/3">
              Start by pressing the button below and start responding to
              requests in no-time!
            </p>
            <button
              className="button-primary-icon flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
              onClick={toggleDrawer}
              disabled={isOpen}
            >
              <AiOutlinePlus
                className="cursor-pointer"
                size={20}
                title="Approve"
              />
              Create new opportunity
            </button>
          </div>
        </div>
      ) : (
        <div className="doc-list-holder rounded rounded-xl shadow border border-gray-100">
          <div className="doc-list-header py-6 pl-8 pr-5 rounded rounded-xl bg-white flex items-center">
            <h2 className="font-normal text-gray-900 mb-0 grow">
              Opportunities list
            </h2>
            <div className="text-center">
              <div
                data-tooltip-content={"Create new opportunity"}
                data-tooltip-id="create-new-project"
                onClick={toggleDrawer}
                className="p-2 my-2 inline-flex items-center rounded-md cursor-pointer group relative"
              >
                  <img src="/icons/add.png" className="w-6 h-6" alt="add" />
                  <Tooltip id="create-new-project" />
              </div>
            </div>
          </div>
          <table className="min-w-full text-[15px]">
            <thead>
              <tr>
                <th className="w-2/3 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-50">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
            {!loaded() ? (
              <tr>
                <td>
                  <TableLoader />
                </td>
              </tr>
            ) : projects.map((doc, index) => (
                <tr
                  key={index}
                  className="group cursor-pointer border-t border-gray-200 first:border-t-0 relative text-gray-700 hover:bg-brandSubtle hover:text-brand even:bg-gray-10"
                  onClick={() => handleRowClick(doc.id)}
                >
                  <td className="py-4 pl-8 group-last:rounded-0 group-last:rounded-bl-xl">
                    <div className="group relative min-w-full min-h-full">
                      {editingId === doc.id ? (
                        <div className="relative">
                          <input
                            type="text"
                            value={editingTitle}
                            onChange={(e) => setEditingTitle(e.target.value)}
                            autoFocus
                            className="block w-full py-2 text-gray-900 bg-gray-100 rounded hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-opacity-50"
                            onKeyUp={onEnterPress}
                          />
                          <div className="absolute right-1 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                            <AiOutlineCheck
                              onClick={sendUpdateUprojectChanges}
                              size={20}
                              className="hover:text-green-500 cursor-pointer"
                            />
                            <AiOutlineClose
                              onClick={resetEditModel}
                              size={20}
                              className="hover:text-red-500 cursor-pointer"
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          {deletingId === doc.id ? (
                            <div className="">
                              <div className="flex items-center gap-4">
                                {doc.title}
                              </div>
                              <div className="absolute right-4 top-1/2 transform -translate-y-1/2 flex items-center space-x-2 bg-gray-100 rounded-lg shadow-md p-2">
                                <span className="text-sm mr-2">
                                  Are you sure?
                                </span>
                                <AiOutlineCheck
                                  className="hover:text-green-500 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleConfirmDelete(doc.id);
                                  }}
                                  size={20}
                                />
                                <AiOutlineClose
                                  className="hover:text-red-500 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCancelDelete();
                                  }}
                                  size={20}
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              {editingId !== doc.id && (
                                <div className="w-full">
                                  <div className="flex items-center gap-4 pr-16">
                                    {doc.title}
                                  </div>
                                  <AiOutlineEdit
                                    className="hover:text-gray-500 absolute right-10 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      stratEditing(doc);
                                    }}
                                  />
                                  <AiOutlineDelete
                                    className="hover:text-red-500 absolute right-4 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100 cursor-pointer"
                                    size={20}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteClick(doc.id);
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Home;
