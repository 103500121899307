import React from "react";
import { Link, useLocation } from "react-router-dom";
import StatusFlag from "./utils/StatusFlag";
import {
  Project,
  RequiredResourcesSummary,
  Requirement,
} from "../store/project/types";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { logEvent } from "../utils";

export const DashboardTechnicalOverview = ({
  project,
}: {
  project: Project | null;
}) => {
  const location = useLocation();

  const resourceSummary = useSelector<
    RootState,
    RequiredResourcesSummary | null
  >((state) => {
    return state.projectData.currentResourceSummary;
  });

  const getWorkBreakDownStatus = (requirement: Requirement) => {
    if (requirement.isWorkBreakdownApproved) {
      return "approved";
    }
    if (requirement.isScopeApproved) {
      return "in_approval";
    }
    return "not_started";
  };

  const getResourceStatus = (requirement: Requirement) => {
    if (requirement.isResourcesApproved) {
      return "approved";
    }
    if (requirement.isWorkBreakdownApproved) {
      return "in_approval";
    }
    return "not_started";
  };

  const trackLinkClick = (to: string) => {
    logEvent("Dashboard Card Click", {
      to,
    });
  };

  if (!project?.requirement) {
    return null;
  }

  return (
    <div className="col-span-3 min-h-80 shadow border border-gray-200 rounded rounded-xl mb-6">
      <div className="grid grid-cols-3 gap-6 pt-4 pl-6 pr-6 rounded rounded-xl bg-white">
        <Link
          className="text-brand font-bold grid-item"
          to={`scoping/${project?.requirement.id}`}
          onClick={() => trackLinkClick("Scope")}
        >
          <div className="border border-gray-200 rounded rounded-xl">
            <div className="rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full">
              <div className="pt-4 pl-6 pr-6 grow">
                <h3 className="font-bold text-gray-700 mb-0">Scope</h3>
                <div className="flex justify-between items-center my-8">
                  <div className="text-gray-700 text-4xl font-bold">
                    <div className="flex flex-col">
                      <span>
                        {project.requirement.isScopeApproved ? "Clear" : ""}
                        &nbsp;
                      </span>
                      <span className="text-sm text-gray-400">&nbsp;</span>
                    </div>
                  </div>
                  <StatusFlag
                    status={
                      project.requirement.isScopeApproved
                        ? "approved"
                        : "in_approval"
                    }
                  />
                </div>
              </div>
              <div className="py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0">
                <img src="/icons/view.png" className="w-5" alt="" />
                <span>View</span>
              </div>
            </div>
          </div>
        </Link>

        <Link
          className={`text-brand font-bold grid-item ${
            getWorkBreakDownStatus(project.requirement) === "not_started"
              ? "text-gray-400 cursor-not-allowed"
              : ""
          }`}
          to={
            getWorkBreakDownStatus(project.requirement) !== "not_started"
              ? `work-breakdown-structure/${project.requirement.id}`
              : location.pathname
          }
          onClick={() => trackLinkClick("WBS")}
        >
          <div className="border border-gray-200 rounded rounded-xl">
            <div
              className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                getWorkBreakDownStatus(project.requirement) === "not_started"
                  ? "hover:bg-white"
                  : ""
              }`}
            >
              <div className="pt-4 pl-6 pr-6 grow">
                <h3 className="font-bold text-gray-700 mb-0">
                  Work Breakdown Structure
                </h3>
                <div className="flex justify-between items-center my-8">
                  <div className="text-gray-700 text-4xl font-bold">
                    <div className="flex flex-col">
                      <span>
                        {getWorkBreakDownStatus(project.requirement) ===
                        "approved"
                          ? "100%"
                          : "0%"}
                      </span>
                      <span className="text-sm text-gray-400">&nbsp;</span>
                    </div>
                  </div>
                  <StatusFlag
                    status={getWorkBreakDownStatus(project.requirement)}
                  />
                </div>
              </div>
              <div
                className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                  getWorkBreakDownStatus(project.requirement) === "not_started"
                    ? "text-gray-400 opacity-50"
                    : ""
                }`}
              >
                <img
                  src="/icons/view.png"
                  className={`w-5 ${
                    getWorkBreakDownStatus(project.requirement) ===
                    "not_started"
                      ? "opacity-30"
                      : ""
                  } `}
                  alt=""
                />
                <span>View</span>
              </div>
            </div>
          </div>
        </Link>

        <Link
          className={`text-brand font-bold grid-item ${
            getResourceStatus(project.requirement) === "not_started"
              ? "text-gray-400 cursor-not-allowed"
              : ""
          }`}
          to={
            getResourceStatus(project.requirement) !== "not_started"
              ? `resource-planning/${project.requirement.id}`
              : location.pathname
          }
          onClick={() => trackLinkClick("Resources")}
        >
          <div className="border border-gray-200 rounded rounded-xl">
            <div
              className={`rounded rounded-xl bg-white hover:bg-brandSubtle flex flex-col h-full ${
                getResourceStatus(project.requirement) === "not_started"
                  ? "hover:bg-white"
                  : ""
              }`}
            >
              <div className="pt-4 pl-6 pr-6 grow">
                <h3 className="font-bold text-gray-700 mb-0">
                  Project Plan Overview
                </h3>
                <div className="flex justify-between items-center my-8">
                  <div className="flex flex-col">
                    <span className="flex flex-col text-gray-700 text-4xl font-bold">
                      {getResourceStatus(project.requirement) !==
                      "not_started" ? (
                        <>
                          <span>
                            {resourceSummary?.summary.reduce(
                              (acc, { effort }) => acc + +effort,
                              0
                            )}{" "}
                            h
                          </span>
                          <span className="text-sm text-gray-400">
                            Total effort
                          </span>
                        </>
                      ) : (
                        <>
                          <span>&nbsp;</span>
                          <span className="text-sm text-gray-400">&nbsp;</span>
                        </>
                      )}
                    </span>
                  </div>

                  <StatusFlag status={getResourceStatus(project.requirement)} />
                </div>
              </div>
              <div
                className={`py-4 px-6 flex items-center justify-end gap-1 border border-gray-200 border-b-0 border-l-0 border-r-0 ${
                  getResourceStatus(project.requirement) === "not_started"
                    ? "text-gray-400 opacity-50"
                    : ""
                }`}
              >
                <img
                  src="/icons/view.png"
                  className={`w-5 ${
                    getResourceStatus(project.requirement) === "not_started"
                      ? "opacity-30"
                      : ""
                  } `}
                  alt=""
                />
                <span>View</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
