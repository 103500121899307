import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../../utils";
import { HighLevelEstimationTrainingData } from "./types";

const highLevelEstimationApi = createApi({
  reducerPath: "highLevelEstimationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/high-level-estimation",
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    },
  }),
  tagTypes: ["HighLevelEstimation"],
  endpoints: (builder) => ({
    getHighLevelEstimations: builder.query<
      { data: HighLevelEstimationTrainingData[] },
      void
    >({
      query: () => "/",
      providesTags: [{ type: "HighLevelEstimation" }],
    }),
    createHighLevelEstimation: builder.mutation<
      { data: HighLevelEstimationTrainingData },
      Partial<HighLevelEstimationTrainingData>
    >({
      query: (highLevelEstimationData) => {
        return {
          url: "",
          method: "POST",
          body: highLevelEstimationData,
        };
      },
      invalidatesTags: [{ type: "HighLevelEstimation" }],
    }),
    updateHighLevelEstimation: builder.mutation<
      HighLevelEstimationTrainingData,
      Partial<HighLevelEstimationTrainingData>
    >({
      query: ({ id, ...highLevelEstimationData }) => {
        return {
          url: `/${id}`,
          method: "PATCH",
          body: highLevelEstimationData,
        };
      },
      invalidatesTags: [{ type: "HighLevelEstimation" }],
    }),
    uploadEstimationFile: builder.mutation<
      {
        data: HighLevelEstimationTrainingData;
        message: string;
      },
      { file: File }
    >({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: "/upload-estimation-file",
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteHighLevelEstimation: builder.mutation<null, string>({
      query: (id: string) => {
        return {
          url: `/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "HighLevelEstimation" }],
    }),
    fetchEstimationFileContent: builder.query<any, string>({
      query: (id) => `/file-content/${id}`,
    }),
  }),
});

export const {
  useGetHighLevelEstimationsQuery,
  useCreateHighLevelEstimationMutation,
  useUpdateHighLevelEstimationMutation,
  useUploadEstimationFileMutation,
  useDeleteHighLevelEstimationMutation,
  useFetchEstimationFileContentQuery,
} = highLevelEstimationApi;

export const highLevelEstimationApiMiddleware =
  highLevelEstimationApi.middleware;

export default highLevelEstimationApi.reducer;
