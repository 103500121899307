import React, { useEffect, useState } from "react";
import {
  AiOutlineDownload,
  AiOutlineFileText,
  AiOutlineLoading3Quarters,
  AiOutlineWarning,
} from "react-icons/ai";
import {
  ProposalDocumentStatus,
  ProposalDocument,
  setProposalSettings,
} from "../../store/project/projectSlice";
import {
  downloadProposal,
  useGenerateProposalMutation,
  useNewProposalDocumentMutation,
} from "../../store/project/projectApiService";
import { useDispatch } from "react-redux";
import { logEvent } from "../../utils";
import { ProposalDocumentSection } from "../../store/proposal/types";
import { toast } from "react-toastify";

interface ProposalHeaderProps {
  proposalSettings: ProposalDocument | null;
  selectedComponents: ProposalDocumentSection[];
}

const ProposalHeader: React.FC<ProposalHeaderProps> = ({
  proposalSettings,
  selectedComponents,
}) => {
  const status =
    proposalSettings?.status || ProposalDocumentStatus.UNDER_CONSTRUCTION;

  const dispatch = useDispatch();
  const [generateProposal] = useGenerateProposalMutation();
  const [newProposalDocument] = useNewProposalDocumentMutation();
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showDelayMessage, setShowDelayMessage] = useState(false);

  useEffect(() => {
    let timer: any;
    if (isTimerRunning) {
      timer = setTimeout(() => {
        setIsTimerRunning(false);
        setShowDelayMessage(true);
      }, 60000);
    }
    return () => {
      clearTimeout(timer);
    } 
  }, [isTimerRunning]);

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  const renderButtonContent = () => {
    switch (status) {
      case ProposalDocumentStatus.UNDER_CONSTRUCTION:
        return (
          <span className="flex items-center gap-2">
            <AiOutlineFileText className="w-5 h-5" />
            Generate Document
          </span>
        );
      case ProposalDocumentStatus.ERROR:
        return (
          <span className="flex items-center gap-2">
            <AiOutlineWarning className="w-5 h-5" />
            Retry Generation
          </span>
        );
      case ProposalDocumentStatus.GENERATING:
        return (
          <span className="flex items-center gap-2">
            <AiOutlineLoading3Quarters className="w-5 h-5 animate-spin" />
            Generating
          </span>
        );
      case ProposalDocumentStatus.READY:
        return (
          <span className="flex items-center gap-2">
            <AiOutlineDownload className="w-5 h-5" />
            Download
          </span>
        );
      default:
        return null;
    }
  };

  const isButtonDisabled = () => {
    return status === ProposalDocumentStatus.GENERATING;
  };

  const newDocumentClick = (requirementId: string, proposalId: string) => {
    newProposalDocument({
      id: proposalSettings?.id || "",
      requirementId: proposalSettings?.requirementId || "",
    })
      .unwrap()
      .then((data) => {
        logEvent("New Document", {
          requirementId: requirementId,
        });
        // Update proposal settings with no sections pre-selected
        const activeDocument = {
          ...data.data.activeDocument,
          sections: data.data.activeDocument?.sections.map((section) => ({
            ...section,
            selected: false,
          })),
        };
        dispatch(
          setProposalSettings({
            requirementId: activeDocument.requirementId,
            allDocuments: data.data.allDocuments,
            activeDocument: activeDocument,
          })
        );
      })
      .catch((err) => {
        toast.error(err?.data?.message || "Error creating new document");
      });
  };
  

  const handleOnButtonClick = () => {
    switch (status) {
      case ProposalDocumentStatus.UNDER_CONSTRUCTION:
      case ProposalDocumentStatus.ERROR:
        startTimer();
        generateProposal({
          id: proposalSettings?.id || "",
          requirementId: proposalSettings?.requirementId || "",
        })
          .unwrap()
          .then((data) => {
            logEvent("Generate Proposal", {
              proposalId: data.data.activeDocument!.id,
            });
            dispatch(
              setProposalSettings({
                requirementId: data.data.activeDocument!.requirementId,
                allDocuments: data.data.allDocuments,
                activeDocument: data.data.activeDocument,
              })
            );
          });
        break;
      case ProposalDocumentStatus.GENERATING:
        break;
      case ProposalDocumentStatus.READY:
        downloadProposal(
          proposalSettings?.id!,
          proposalSettings?.name!,
          "docx"
        );
        logEvent("Download Proposal", { proposalId: proposalSettings?.id });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex items-start rfi-header">
        <div className="flex-grow mb-4">
          <h2 className="grow text-[1.5rem] font-semibold">Manage Proposal</h2>
        </div>
        {proposalSettings && status === ProposalDocumentStatus.READY ? (
          <div className="flex ml-4">
            <button
              onClick={() =>
                newDocumentClick(
                  proposalSettings?.id!,
                  proposalSettings?.requirementId!
                )
              }
              className="button-secondary-yellow"
            >
              <AiOutlineFileText className="w-5 h-5" />
              New Document
            </button>
          </div>
        ) : null}

        <div className="flex">
          {selectedComponents?.length > 0 &&
          status !== ProposalDocumentStatus.READY ? (
            <button
              onClick={handleOnButtonClick}
              className="button-primary px-4 py-2 shadow-sm border border-gray-300 px-3 rounded-lg text-lg hover:bg-brandHover focus:outline-none focus:ring focus:ring-green-200 flex items-center gap-2 disabled:text-gray-400 disabled:bg-gray-200 disabled:border-gray-300 disabled:cursor-not-allowed"
              disabled={isButtonDisabled()}
            >
              {renderButtonContent()}
            </button>
          ) : null}
        </div>
      </div>
      {status === ProposalDocumentStatus.GENERATING && showDelayMessage && (
        <div className="flex w-full items-center justify-center py-4 bg-brandYellow rounded-xl">
          <span className="text-sm text-yellow-600 font-semibold text-center">
            Seems like you have a hell of a proposal cooking up!
            This may take a little longer. If it doesn't complete within a minute, please refresh the page.
          </span>
        </div>
      )}
    </>
  );
};

export default ProposalHeader;
