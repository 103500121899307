import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResourceType } from "../../store/resourceType/types";
import { RootState } from "../../store";
import {
  useCreateResourceTypeMutation,
  useDeleteResourceTypeMutation,
  useGetResourceTypesQuery,
  useUpdateResourceTypeMutation,
} from "../../store/resourceType/resourceTypeApiService";
import { setResourceTypes } from "../../store/resourceType/resourceTypeSlice";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
} from "react-icons/ai";
import { TableLoader } from "../utils/ContentLoader";
import { toCurrencyFormat } from "../utils/helperFunctions";
import { toast } from "react-toastify";
import { logEvent } from "../../utils";
import ConfirmCancelButtons from "../utils/ConfirmCancelButtons";

const StaffRatesList: React.FC = () => {
  const [deletingId, setDeletingId] = useState<string>("");
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingContent, setEditingContent] = useState<string>("");
  const [editingCostPerHour, setEditingCostPerHour] = useState<number>(0);
  const [editingDefaultHourlyRate, setEditingDefaultHourlyRate] =
    useState<number>(0);
  const rateNameRef = useRef<HTMLInputElement>(null);
  const [createResourceType] = useCreateResourceTypeMutation();
  const [updateResourceType] = useUpdateResourceTypeMutation();
  const [deleteResourceType] = useDeleteResourceTypeMutation();

  const dispatch = useDispatch();
  const resourceTypes = useSelector<RootState, ResourceType[]>(
    (state) => state.resourceTypeData.resourceTypes
  );

  const { data, isSuccess } = useGetResourceTypesQuery();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setResourceTypes(data.data));
    }
  }, [isSuccess, data, dispatch]);

  useEffect(() => {
    if (editingId && rateNameRef.current) {
      rateNameRef.current.focus();
      rateNameRef.current.select();
    }
  }, [editingId]);

  const handleDelete = (id: string) => {
    setDeletingId(id);
  };

  const handleEdit = (rate: ResourceType) => {
    setEditingId(rate.id);
    setEditingContent(rate.name);
    setEditingCostPerHour(rate.averageCostPerHour);
    setEditingDefaultHourlyRate(rate.defaultHourlyRate);
  };

  const resetEditMode = () => {
    setEditingId(null);
    setEditingContent("");
    setEditingCostPerHour(0);
    setEditingDefaultHourlyRate(0);
  };

  const handleConfirmDelete = async (id: string) => {
    try {
      await deleteResourceType(id).unwrap();
      toast.success("Successfully deleted!");
      setDeletingId("");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting resource type!");
    }
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  const handleSaveEdit = async (id: string) => {
    try {
      await updateResourceType({
        id,
        name: editingContent,
        averageCostPerHour: editingCostPerHour,
        defaultHourlyRate: editingDefaultHourlyRate,
      }).unwrap();
      toast.success("Staff Rate updated successfully!");
      resetEditMode();
    } catch (err: any) {
      toast.error(err?.data?.message || "Error creating/updating staff rate!");
    }
  };

  const createNewRate = async () => {
    logEvent("Create Resource Type");

    const newResourceType: Partial<ResourceType> = {
      id: "",
      name: "[New Resource Type]",
      averageCostPerHour: 0,
      defaultHourlyRate: 0,
      rank: resourceTypes.length + 1,
    };
    try {
      await createResourceType(newResourceType)
        .unwrap()
        .then((result) => {
          dispatch(setResourceTypes([result.data, ...resourceTypes]));
          handleEdit(result.data);
        });
    } catch (err: any) {
      toast.error(err?.data?.message || "Error creating new resource type!");
    }
  };

  return (
    <div className="p-4 pb-8 mt-4">
      <div className="flex items-start rfi-header">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">Staff Rates</h2>
        </div>
      </div>
      <div className="container shadow border border-gray-200 rounded rounded-xl">
        <div className="flex items-center pr-8">
          <h2 className="text-[1.2rem] font-semibold py-8 px-8 mb-0 grow">
            Resource Types
          </h2>
          <button
            className="button-secondary flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
            onClick={createNewRate}
          >
            <AiOutlinePlus size={20} title="Add Resource Type" />
            Add Resource Type
          </button>
        </div>

        <div>
          <table className="w-full text-[15px] table-fixed">
            <thead>
              <tr>
                <th className="w-1/3 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Type
                </th>
                <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Cost per Hour
                </th>
                <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Hourly Rate
                </th>
                <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100"></th>
              </tr>
            </thead>
            <tbody>
              {!resourceTypes ? (
                <tr>
                  <td>
                    <TableLoader />
                  </td>
                </tr>
              ) : (
                resourceTypes.map((rate, index) => (
                  <tr
                    key={index}
                    className={`even:bg-gray-50 text-gray-700 hover:bg-brandSubtle hover:text-brand`}
                  >
                    <td className="py-4 pl-8  font-semibold h-full">
                      {editingId === rate.id ? (
                        <input
                          type="text"
                          ref={rateNameRef}
                          value={editingContent}
                          onChange={(e) => setEditingContent(e.target.value)}
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSaveEdit(rate.id);
                            } else if (e.key === "Escape") {
                              e.preventDefault();
                              resetEditMode();
                            }
                          }}
                        />
                      ) : (
                        rate.name
                      )}
                    </td>
                    <td className="py-4 pl-8 font-semibold h-full">
                      {editingId === rate.id ? (
                        <input
                          type="number"
                          value={editingCostPerHour}
                          onChange={(e) =>
                            setEditingCostPerHour(parseInt(e.target.value))
                          }
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                          onKeyDown={(
                            e: React.KeyboardEvent<HTMLInputElement>
                          ) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              handleSaveEdit(rate.id);
                            } else if (e.key === "Escape") {
                              e.preventDefault();
                              resetEditMode();
                            }
                          }}
                        />
                      ) : (
                        toCurrencyFormat(rate.averageCostPerHour)
                      )}
                    </td>
                    <td className="py-4 pl-8 font-semibold h-full">
                      {editingId === rate.id ? (
                        <div className="flex items-center gap-2">
                          <input
                            type="number"
                            value={editingDefaultHourlyRate}
                            onChange={(e) =>
                              setEditingDefaultHourlyRate(
                                parseInt(e.target.value)
                              )
                            }
                            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-brand"
                            onKeyDown={(
                              e: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSaveEdit(rate.id);
                              } else if (e.key === "Escape") {
                                e.preventDefault();
                                resetEditMode();
                              }
                            }}
                          />
                          <ConfirmCancelButtons
                            onConfirm={() => handleSaveEdit(rate.id)}
                            onCancel={resetEditMode}
                            confirmTooltipId={`confirmEdit-${rate.id}`}
                            cancelTooltipId={`cancelEdit-${rate.id}`}
                          />
                        </div>
                      ) : (
                        toCurrencyFormat(rate.defaultHourlyRate)
                      )}
                    </td>
                    <td className="py-4 pl-4 pr-6 font-semibold h-full">
                      {deletingId === rate.id ? (
                        <div className="flex items-center gap-2 justify-end w-full">
                          <span className="mr-1">Are you sure?</span>
                          <ConfirmCancelButtons
                            onConfirm={() => handleConfirmDelete(rate.id)}
                            onCancel={handleCancelDelete}
                            confirmTooltipId={`delete-${rate.id}`}
                            cancelTooltipId={`cancelDelete-${rate.id}`}
                          />
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 justify-end w-full">
                          <AiOutlineEdit
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleEdit(rate)}
                            size={25}
                            title="Edit"
                          />
                          <AiOutlineDelete
                            className="text-red-500 cursor-pointer hover:text-red-700"
                            size={25}
                            title="Delete"
                            onClick={() => handleDelete(rate.id)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StaffRatesList;
