import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDropzone } from "react-dropzone";

import { toast } from "react-toastify";
import {
  useCreateHighLevelEstimationMutation,
  useUpdateHighLevelEstimationMutation,
  useUploadEstimationFileMutation,
  useFetchEstimationFileContentQuery,
} from "../../store/highLevelEstimationDataSet/highLevelEstimationApiService";
import { HighLevelEstimationTrainingData } from "../../store/highLevelEstimationDataSet/types";
import Mammoth from "mammoth";
import Modal from "react-modal";

const customStyles = {
  content: {
    zIndex: 2000,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 1500,
  },
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  totalPrice: Yup.number().required("Total Price is required"),
  employeeDays: Yup.number().required("Employee Days is required"),
  durationWeeks: Yup.number().required("Duration is required"),
});

type NewHighLevelEstimationProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  estimation?: Partial<HighLevelEstimationTrainingData> | null;
  highLevelEstimations: HighLevelEstimationTrainingData[];
};

const NewHighLevelEstimation = ({
  isOpen,
  toggleDrawer,
  estimation,
}: NewHighLevelEstimationProps) => {
  const [createHighLevelEstimation] = useCreateHighLevelEstimationMutation();
  const [updateHighLevelEstimation] = useUpdateHighLevelEstimationMutation();
  const [uploadEstimationFile] = useUploadEstimationFileMutation();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [filePreview, setFilePreview] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: fileContentData, error: fileContentError } =
    useFetchEstimationFileContentQuery(estimation?.id || "", {
      skip: !estimation?.id,
    });

  const formik = useFormik({
    initialValues: {
      id: estimation?.id || "",
      name: estimation?.name || "",
      totalPrice: estimation?.totalPrice || 0,
      employeeDays: estimation?.employeeDays || 0,
      durationWeeks: estimation?.durationWeeks || 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (estimation?.id) {
          await updateHighLevelEstimation({
            ...values,
          }).unwrap();

          toast.success("Estimation updated successfully!");
        } else {
          await createHighLevelEstimation(values).unwrap();
          toast.success("Estimation created successfully!");
        }
        toggleDrawer();
      } catch (err: any) {
        toast.error(
          err?.data?.message || "Error creating/updating estimation!"
        );
      }
    },
  });

  const onFileClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        try {
          const file = acceptedFiles[0];
          const result = await uploadEstimationFile({ file }).unwrap();

          formik.setFieldValue("id", result.data.id);
          setFileUploaded(true);

          if (file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onloadend = () => {
              setFilePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
          } else if (
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            const arrayBuffer = await file.arrayBuffer();
            const result = await Mammoth.convertToHtml({ arrayBuffer });
            setFilePreview(result.value);
          }

          toast.success("File uploaded successfully!");
        } catch (err: any) {
          toast.error(err?.data?.message || "Error uploading file!");
        }
      }
    },
    [uploadEstimationFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
  });
  useEffect(() => {
    if (estimation) {
      formik.setValues({
        id: estimation.id || "",
        name: estimation.name || "",
        totalPrice: estimation.totalPrice || 0,
        employeeDays: estimation.employeeDays || 0,
        durationWeeks: estimation.durationWeeks || 0,
      });
    } else {
      formik.resetForm();
      formik.setValues({
        id: "",
        name: "",
        totalPrice: 0,
        employeeDays: 0,
        durationWeeks: 0,
      });
      setFileUploaded(false);
      setFilePreview(null);
    }
    if (fileContentData) {
      setFileUploaded(true);
      if (fileContentData.type === "application/pdf") {
        setFilePreview(fileContentData.base64Data);
      } else if (
        fileContentData.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        setFilePreview(fileContentData.content);
      }
    } else if (fileContentError) {
      toast.error("Error fetching file content for preview.");
    }
  }, [fileContentData, fileContentError]);

  return (
    <div
      className={`fixed top-0 right-0 w-1/2 h-full bg-white shadow-md z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="m-4 mt-12">
        <h2 className="text-lg font-semibold m-2">
          {estimation?.id
            ? "Edit Estimation Training Data"
            : "Create New Estimation Training Data"}
        </h2>
        <form onSubmit={formik.handleSubmit}>
          <div className="subtitle my-4">
            <h2>Upload a document</h2>
            <p className="text-[14px] text-gray-600">
              Upload your past project requests or RFP
            </p>
          </div>

          {!fileUploaded && (
            <div className="my-6">
              <div {...getRootProps()} className="upload">
                <input {...getInputProps()} />
                <div className="text-sm text-center">
                  <div className="p-2 my-2 shadow inline-flex items-center border border-gray-200 rounded rounded-md">
                    <img
                      src="/icons/upload.png"
                      className="w-5 h-5"
                      alt="upload"
                    />
                  </div>
                  <br />
                  <span className="text-brand underline font-bold">
                    Click to upload
                  </span>
                  <br /> or drag & drop docx or pdf file here
                </div>
              </div>
            </div>
          )}

          {fileUploaded && (
            <div
              className="p-4 border border-gray-300 rounded-md cursor-pointer"
              onClick={onFileClick}
            >
              <p className="text-sm text-gray-600">
                File Uploaded. Click to preview.
              </p>
            </div>
          )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            contentLabel="Example Modal"
            style={customStyles}
          >
            <div className="p-4">
              <h3 className="text-lg font-semibold">File Preview</h3>
              <div className="border p-4 mt-2">
                {filePreview &&
                filePreview.startsWith("data:application/pdf") ? (
                  <iframe
                    src={filePreview}
                    width="100%"
                    height="500px"
                    title="PDF Preview"
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: filePreview || "<div>Loading...</div>",
                    }}
                  />
                )}
              </div>
              <button
                onClick={() => setIsModalOpen(false)}
                className="button-primary mt-4"
              >
                Close
              </button>
            </div>
          </Modal>

          <input type="hidden" name="id" value={formik.values.id} />
          <div className="m-2 mt-12">
            <label className="block">
              Name of the Project *
              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="Enter estimation name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500">{formik.errors.name}</div>
            ) : null}
          </div>
          <div className="m-2 mt-4">
            <label className="block">
              Total Price*
              <input
                type="number"
                name="totalPrice"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.totalPrice}
                placeholder="Enter total price"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.totalPrice && formik.errors.totalPrice ? (
              <div className="text-red-500">{formik.errors.totalPrice}</div>
            ) : null}
          </div>
          <div className="m-2 mt-4">
            <label className="block">
              Employee Days*
              <input
                type="number"
                name="employeeDays"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.employeeDays}
                placeholder="Enter employee days"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.employeeDays && formik.errors.employeeDays ? (
              <div className="text-red-500">{formik.errors.employeeDays}</div>
            ) : null}
          </div>
          <div className="m-2 mt-4">
            <label className="block">
              Duration (Weeks)*
              <input
                type="number"
                name="durationWeeks"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.durationWeeks}
                placeholder="Enter duration in weeks"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                required
              />
            </label>
            {formik.touched.durationWeeks && formik.errors.durationWeeks ? (
              <div className="text-red-500">{formik.errors.durationWeeks}</div>
            ) : null}
          </div>
          <div className="m-4 mt-8 flex items-center justify-center grow">
            <button
              type="submit"
              className="button-primary"
              disabled={!fileUploaded}
            >
              {estimation?.id ? "Update" : "Create"}
            </button>
            <button
              type="button"
              onClick={toggleDrawer}
              className="button-secondary w-[1/3] ml-4"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewHighLevelEstimation;
