import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import {
  useCreateHighLevelEstimationMutation,
  useDeleteHighLevelEstimationMutation,
  useGetHighLevelEstimationsQuery,
  useUpdateHighLevelEstimationMutation,
} from "../../store/highLevelEstimationDataSet/highLevelEstimationApiService";
import { setHighLevelEstimations } from "../../store/highLevelEstimationDataSet/highLevelEstimationSlice";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineEye,
} from "react-icons/ai";
import { TableLoader } from "../utils/ContentLoader";
import { toast } from "react-toastify";
import ConfirmCancelButtons from "../utils/ConfirmCancelButtons";
import { HighLevelEstimationTrainingData } from "../../store/highLevelEstimationDataSet/types";
import NewHighLevelEstimation from "./NewHighLevelEstimation";
import { toCurrencyFormat } from "../utils/helperFunctions";
import ViewDetailsDrawer from "./ViewDetailsDrawer";

const HighLevelEstimationDataSet: React.FC = () => {
  const [deletingId, setDeletingId] = useState<string>("");
  const [viewingData, setViewingData] =
    useState<Partial<HighLevelEstimationTrainingData> | null>(null);
  const [editingData, setEditingData] =
    useState<Partial<HighLevelEstimationTrainingData | null>>(null);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);

  const [deleteHighLevelEstimation] = useDeleteHighLevelEstimationMutation();

  const dispatch = useDispatch();
  const highLevelEstimations = useSelector<
    RootState,
    HighLevelEstimationTrainingData[]
  >((state) => state.highLevelEstimationData.highLevelEstimations);

  const { data, isSuccess } = useGetHighLevelEstimationsQuery();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setHighLevelEstimations(data.data));
    }
  }, [isSuccess, data, dispatch]);

  const handleDelete = (id: string) => {
    setDeletingId(id);
  };

  const handleEdit = (estimation: HighLevelEstimationTrainingData) => {
    setEditingData(estimation);
    setIsEditOpen(true);
  };

  const handleViewDetails = (estimation: HighLevelEstimationTrainingData) => {
    setViewingData(estimation);
    setIsViewOpen(true);
  };

  const handleCreate = () => {
    setEditingData(null);
    setIsEditOpen(true);
  };

  const toggleEditDrawer = () => {
    setIsEditOpen(!isEditOpen);
  };

  const toggleViewDrawer = () => {
    setIsViewOpen(!isViewOpen);
  };

  const handleConfirmDelete = async (id: string) => {
    try {
      await deleteHighLevelEstimation(id).unwrap();
      toast.success("Successfully deleted!");
      setDeletingId("");
    } catch (err: any) {
      toast.error(err?.data?.message || "Error deleting estimation!");
    }
  };

  const handleCancelDelete = () => {
    setDeletingId("");
  };

  return (
    <div className="p-4 pb-8 mt-4">
      <div className="flex items-start rfi-header">
        <div className="flex grow gap-6 mb-4 items-center">
          <h2 className="grow text-[1.5rem] font-semibold">
            High-Level Estimations
          </h2>
        </div>
      </div>
      <div className="container shadow border border-gray-200 rounded rounded-xl">
        <div className="flex items-center pr-8">
          <h2 className="text-[1.2rem] font-semibold py-8 px-8 mb-0 grow">
            Estimation Data Sets
          </h2>
          <button
            className="button-secondary flex items-center justify-center gap-2 px-4 focus:outline-none focus:ring focus:ring-green-200"
            onClick={handleCreate}
          >
            <AiOutlinePlus size={20} title="Add Estimation" />
            Add Estimation
          </button>
        </div>

        <div>
          <table className="w-full text-[15px] table-fixed">
            <thead>
              <tr>
                <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Name
                </th>
                <th className="w-1/4 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  File Name
                </th>
                <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Total Price
                </th>
                <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100">
                  Duration (Weeks)
                </th>
                <th className="w-1/6 py-3 px-8 border-b border-t border-gray-200 text-left text-gray-600 tracking-wider font-bold bg-gray-100"></th>
              </tr>
            </thead>
            <tbody>
              {!highLevelEstimations ? (
                <tr>
                  <td>
                    <TableLoader />
                  </td>
                </tr>
              ) : (
                highLevelEstimations.map((estimation, index) => (
                  <tr
                    key={index}
                    className={`even:bg-gray-50 text-gray-700 hover:bg-brandSubtle hover:text-brand`}
                  >
                    <td className="py-4 pl-8 font-semibold h-full">
                      {estimation.name}
                    </td>
                    <td className="py-4 pl-8 font-semibold h-full">
                      {estimation.fileName}
                    </td>
                    <td className="py-4 pl-8 font-semibold h-full">
                      {toCurrencyFormat(estimation.totalPrice)}
                    </td>
                    <td className="py-4 pl-8 font-semibold h-full">
                      {estimation.durationWeeks}
                    </td>
                    <td className="py-4 pl-4 pr-6 font-semibold h-full">
                      {deletingId === estimation.id ? (
                        <div className="flex items-center gap-2 justify-end w-full">
                          <span className="mr-1">Are you sure?</span>
                          <ConfirmCancelButtons
                            onConfirm={() => handleConfirmDelete(estimation.id)}
                            onCancel={handleCancelDelete}
                            confirmTooltipId={`delete-${estimation.id}`}
                            cancelTooltipId={`cancelDelete-${estimation.id}`}
                          />
                        </div>
                      ) : (
                        <div className="flex items-center gap-2 justify-end w-full">
                          <AiOutlineEye
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleViewDetails(estimation)}
                            size={25}
                            title="View Details"
                          />
                          <AiOutlineEdit
                            className="hover:text-gray-500 cursor-pointer"
                            onClick={() => handleEdit(estimation)}
                            size={25}
                            title="Edit"
                          />
                          <AiOutlineDelete
                            className="text-red-500 cursor-pointer hover:text-red-700"
                            size={25}
                            title="Delete"
                            onClick={() => handleDelete(estimation.id)}
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <NewHighLevelEstimation
        isOpen={isEditOpen}
        toggleDrawer={toggleEditDrawer}
        estimation={editingData}
        highLevelEstimations={highLevelEstimations}
      />
      {viewingData && (
        <ViewDetailsDrawer
          isOpen={isViewOpen}
          toggleDrawer={toggleViewDrawer}
          estimation={viewingData}
        />
      )}
    </div>
  );
};

export default HighLevelEstimationDataSet;
