import React, { useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { AppState, Auth0Provider, User } from "@auth0/auth0-react";
import { MyProfile } from "./components/MyProfile";
import { SignInCallBack } from "./components/auth/OAuthCallback";
import { Auth } from "./components/auth";
import { useGetConfigQuery } from "./store/configApiService";
import mixpanel from "mixpanel-browser";
import { logEvent } from "./utils";
import { setUserIdentityId } from "./store/userIdentity/userIdentitySlice";
import { useDispatch } from "react-redux";
import Home from "./components/Home";
import { CapabilityCheckList } from "./components/questionnaires/CapabilityChecks";
import CompanyDocuments from "./components/CompanyDocuments";
import withOnboardingRouting from "./components/onboarding/WithOnboardingRouting";
import MainNav from "./components/MainNav";
import { useLocation } from "react-router-dom";
import Questionnaires from "./components/questionnaires/Questionnaires";
import Breadcrumbs from "./components/Breadcrumbs";
import Dashboard from "./components/Dashboard";
import WorkBreakdownStructure from "./components/workbreakdown/WorkBreakdownStructure";
import ViewOpenQuestions from "./components/workbreakdown/ViewOpenQuestions";
import ScopingPage from "./components/workbreakdown/ScopingPage";
import ProposalPage from "./components/workbreakdown/ProposalPage";
import TasksAndSubtasks from "./components/workbreakdown/TasksAndSubtasks";
import ResourceOverview from "./components/workbreakdown/ResourceOverview";
import QuotePage from "./components/workbreakdown/QuotePage";
import { TableLoader } from "./components/utils/ContentLoader";
import ResourceTypeEffortPlanning from "./components/workbreakdown/ResourceTypeEffortPlanning";
import { VerifyEmail } from "./components/auth/VerifyEmail";
import { EditCompanyForm } from "./components/admin/ManageCompany";
import ComingSoon from "./components/ComingSoon";
import StaffRatesList from "./components/staff-rate/StaffRateList";
import { MobileNote } from "./components/auth/MobileNote";
import ManageUsersComponent from "./components/users/ManageUsersComponent";
import HighLevelEstimationDataSet from "./components/training-data-sets/HighLevelEstimationDataSet";
import Modal from "react-modal";
import ProjectSummary from "./components/workbreakdown/ProjectSummary";

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    logEvent("Page View", {
      path: location.pathname,
    });
  }, [location.pathname]);

  return null;
}

const Auth0ProviderWithNavigate = ({ children }: any) => {
  const dispatch = useDispatch();
  const { data } = useGetConfigQuery();
  const navigate = useNavigate();

  const onRedirectCallback = (
    _: AppState | undefined,
    user: User | undefined
  ) => {
    if (user && user.sub) {
      mixpanel.identify(user.sub);
      logEvent("Logged In", {
        username: user.email,
      });
      dispatch(setUserIdentityId(user.sub));
      const returnTo = localStorage.getItem("returnTo");
      if (returnTo) {
        localStorage.removeItem("returnTo");
        const url = new URL(returnTo);
        const relativePath = url.pathname + url.search;
        resolveReferral(new URLSearchParams(url.search));
        navigate(relativePath, { replace: true });
      }
    }
  };

  const resolveReferral = (params: URLSearchParams) => {
    const pricingPlan = params.get("pricingPlan");
    if (pricingPlan) {
      logEvent("WebsiteReferral", {
        pricingPlan: pricingPlan,
      });
      localStorage.setItem("pricingPlan", pricingPlan);
    }
  };

  return (
    <Auth0Provider
      domain={data?.domain!}
      clientId={data?.client_id!}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        audience: data?.audience!,
        redirect_uri: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

function App() {
  const { data, isLoading, error } = useGetConfigQuery();

  useEffect(() => {
    if (!isLoading) {
      mixpanel.init(data?.mixpanel_token!, {
        app_host: window.location.host,
      });
    }
  }, [isLoading, data?.mixpanel_token]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  if (isMobile) {
    return <MobileNote />;
  }

  // const onSignIn = (_: AppState | undefined, user: User | undefined) => {
  //   if (user && user.sub) {
  //     mixpanel.identify(user.sub);
  //     trackUser("Logged In", {
  //       username: user.email,
  //     });
  //     dispatch(setUserIdentityId(user.sub));
  //     const returnTo = sessionStorage.getItem("returnTo");
  //     if (returnTo) {
  //       sessionStorage.removeItem("returnTo");
  //       navigate(returnTo || window.location.pathname, { replace: true });
  //     }
  //   }
  // };

  if (isLoading) return <TableLoader />;
  if (error) {
    return <div>Error loading configuration.</div>;
  }

  return (
    <Router>
      <TrackPageViews />
      <ToastContainer autoClose={3000} position="top-right" />
      <Routes>
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/mobile-note" element={<MobileNote />} />
        <Route
          path="/*"
          element={
            <Auth0ProviderWithNavigate>
              <Auth>
                <AppRoutesWithOnboarding />
              </Auth>
            </Auth0ProviderWithNavigate>
          }
        />
      </Routes>
    </Router>
  );
}

const AppRoutes = () => (
  <div className="m-0 flex h-screen antialiased">
    <MainNav />
    <div className="container pl-72 ml-4 mr-4">
      <Breadcrumbs />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/callback" element={<SignInCallBack />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="questionnaires" element={<Questionnaires />}>
          <Route
            path="capability-checks/:capabilityCheckId"
            element={<CapabilityCheckList />}
          />
          <Route path="library" element={<CompanyDocuments />} />
        </Route>
        <Route path="/dashboard/:projectId" element={<Dashboard />}>
          <Route
            path="summary/:requirementId"
            element={<ProjectSummary />}
          ></Route>
          <Route
            path="work-breakdown-structure/:requirementId"
            element={<WorkBreakdownStructure />}
          >
            <Route
              path="tasks-and-subtasks/:phaseId"
              element={<TasksAndSubtasks />}
            />
          </Route>
          <Route path="scoping/:requirementId" element={<ScopingPage />}>
            <Route
              path="open-questions"
              element={<ViewOpenQuestions />}
            ></Route>
          </Route>
          <Route
            path="proposal/:requirementId"
            element={<ProposalPage />}
          ></Route>
          <Route
            path="resource-planning/:requirementId"
            element={<ResourceOverview />}
          >
            <Route
              path="resource-type-planning/:phaseId/:resourceTypeId"
              element={<ResourceTypeEffortPlanning />}
            />
          </Route>
          <Route path="quote/:requirementId" element={<QuotePage />}></Route>
        </Route>
        <Route path="settings/company" element={<EditCompanyForm />} />
        <Route path="settings/staff-rates" element={<StaffRatesList />} />
        <Route
          path="settings/high-level-estimation-data-set"
          element={<HighLevelEstimationDataSet />}
        />
        <Route
          path="settings/user-management"
          element={<ManageUsersComponent />}
        />
        <Route path="settings/branding" element={<ComingSoon />} />
        <Route path="settings/billing" element={<ComingSoon />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  </div>
);

const AppRoutesWithOnboarding = withOnboardingRouting(AppRoutes);

Modal.setAppElement("#root");

export default App;
