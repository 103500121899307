import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Company } from "../store/company/types";
import { Project } from "../store/project/types";
import { logEvent } from "../utils";
import { AiOutlineRight } from "react-icons/ai";
import Stepper from "./utils/Stepper"; // Import the Stepper component
import { Tooltip } from "react-tooltip";

const MainNav: React.FC = () => {
  const { user, logout } = useAuth0();
  const { projectId } = useParams();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const settingsRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    logEvent("Logout Click", { userId: user?.sub, projectId });
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const company = useSelector<RootState, Company | null>(
    (state) => state.companyData.company
  );

  const location = useLocation();

  const project = useSelector<RootState, Project | null>((state) => {
    return state.projectData.currentProject;
  });

  useEffect(() => {
    const pathnamesToExpand = [
      `/dashboard/${project?.id}/work-breakdown-structure/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/scoping/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/resource-planning/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/quote/${project?.requirement?.id}`,
      `/dashboard/${project?.id}/proposal/${project?.requirement?.id}`,
    ];

    if (pathnamesToExpand.includes(location.pathname)) {
      setIsSettingsOpen(false);
    }
  }, [location.pathname, project]);

  const handleLinkClick = (
    to: string,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    switch (to) {
      case "WBS":
        if (!project?.requirement?.isScopeApproved) {
          e.preventDefault();
        }
        break;
      case "Quote":
        if (!project?.requirement?.isResourcesApproved) {
          e.preventDefault();
        }
        break;
      case "Proposal":
        if (!project?.requirement?.isQuoteApproved) {
          e.preventDefault();
        }
        break;
    }
    logEvent("Main Navigation Click", { to });
  };

  const toggleSettingsMenu = () => {
    setIsSettingsOpen((prev) => !prev);
  };

  // Improved handleClickOutside function
  const handleClickOutside = (event: MouseEvent) => {
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node) &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      setIsSettingsOpen(false);
    }
  };

  const handleMouseLeave = () => {
    setIsSettingsOpen(false);
  };

  // Attach event listeners to handle click outside
  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target as Node) &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (settingsRef.current) {
      settingsRef.current.style.maxHeight = isSettingsOpen
        ? `${settingsRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isSettingsOpen]);

  const steps = [
    {
      title: "Project Summary",
      description: !project?.requirement?.isScopeApproved
        ? "Review the project summary"
        : "Approved!",
      isCompleted: !!project?.requirement?.isScopeApproved,
      isCurrent: location.pathname.includes(
        `/dashboard/${project?.id}/summary/`
      ),
      to: `/dashboard/${project?.id}/summary/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("Summary", e),
    },
    {
      title: "Scoping",
      description: !project?.requirement?.isScopeApproved
        ? "Start the requirements clarification"
        : "Approved!",
      isCompleted: !!project?.requirement?.isScopeApproved,
      isCurrent: location.pathname.includes(
        `/dashboard/${project?.id}/scoping/`
      ),
      to: `/dashboard/${project?.id}/scoping/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("Scope", e),
    },
    {
      title: "Work Breakdown",
      description: !project?.requirement?.isWorkBreakdownApproved
        ? "Start collaborating with your team"
        : "Approved!",
      isCompleted: !!project?.requirement?.isWorkBreakdownApproved,
      isCurrent: location.pathname.includes(
        `/dashboard/${project?.id}/work-breakdown-structure/`
      ),
      to: `/dashboard/${project?.id}/work-breakdown-structure/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("WBS", e),
    },
    {
      title: "Resources",
      description: !project?.requirement?.isResourcesApproved
        ? "Start collaborating with your team"
        : "Approved!",
      isCompleted: !!project?.requirement?.isResourcesApproved,
      isCurrent: location.pathname.includes(
        `/dashboard/${project?.id}/resource-planning/`
      ),
      to: `/dashboard/${project?.id}/resource-planning/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("Resources", e),
    },
    {
      title: "Quote",
      description: !project?.requirement?.isQuoteApproved
        ? "Review quote"
        : "Approved!",
      isCompleted: !!project?.requirement?.isQuoteApproved,
      isCurrent: location.pathname.includes(`/dashboard/${project?.id}/quote/`),
      to: `/dashboard/${project?.id}/quote/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("Quote", e),
    },
    {
      title: "Proposal",
      description: "Generate proposal",
      isCompleted: false, 
      isCurrent: location.pathname.includes(
        `/dashboard/${project?.id}/proposal/`
      ),
      to: `/dashboard/${project?.id}/proposal/${project?.requirement?.id}`,
      nextToResolve: false,
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) =>
        handleLinkClick("Proposal", e),
    },
  ];

  for (let i = 0; i < steps.length; i++) {
    if (i === 0) {
      steps[i].nextToResolve = !steps[i].isCompleted;
    } else {
      steps[i].nextToResolve =
        !steps[i].isCompleted && steps[i - 1].isCompleted;
    }
  }

  return (
    <div
      className="container fixed flex flex-col px-4 py-6 w-72 bg-brandSubtle h-full border-r border-gray-200 shadow"
      ref={menuRef}
      onMouseLeave={handleMouseLeave}
    >
      <NavLink to="/">
        <img src="/images/main-logo.png" className="h-8 ml-1 mb-6" alt="home" />
      </NavLink>

      <nav className="nav flex flex-col gap-y-2 grow overflow-y-auto relative">
        <NavLink
          to="/home"
          className={
            "flex items-center gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/opportunities.png" alt="" />
          <span>Opportunities</span>
        </NavLink>

        {project && (
          <>
            <NavLink
              to={`/dashboard/${project?.id}`}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                handleLinkClick("Dashboard", e)
              }
              className={
                "flex items-center gap-x-2 py-3 px-3 text-gray-700 bg-transparent border border-transparent rounded-md max-w-[260px] inline-block align-bottom"
              }
            >
              <img className="w-8" src="/icons/dashboard.png" alt="" />
              <span
                className="truncate grow flex flex-col"
                style={{ lineHeight: "18px" }}
              >
                <span className="text-[11px] text-brand truncate">
                  {project?.title}
                </span>
                <span>Opportunity cockpit</span>
              </span>
            </NavLink>

            <Stepper steps={steps} />
          </>
        )}
        {/* <NavLink
          to={`/questionnaires`}
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            handleLinkClick("Questionnaires", e)
          }
          className={
            "flex items-center gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/projects.png" alt="" />
          <span>Questionnaires</span>
        </NavLink>
        <NavLink
          to="questionnaires/library"
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            handleLinkClick("KnowledgeBase", e)
          }
          className={
            "flex w-56 items-center self-end gap-x-2 px-3 py-3 text-gray-700 bg-transparent border border-transparent rounded-md"
          }
        >
          <img className="w-6" src="/icons/your-docs.png" alt="" />
          <span>Our Capabilities</span>
        </NavLink> */}
      </nav>

      {/* Footer Section with Profile and Logout */}
      <div className="flex flex-col border-t border-gray-200">
        <div className="w-full drop-shadow-[0_-3px_5px_rgba(0,0,0,0.15)] bg-brandSubtle h-[0.09rem]"></div>
        {/* Settings */}
        <div className="flex flex-col gap-4 w-full">
          <div className="relative flex items-center gap-x-2 px-0 py-1 text-gray-700 bg-transparent border border-transparent rounded-md cursor-pointer">
            <div
              className="flex w-full items-center gap-x-2 py-3 text-gray-700 border border-transparent rounded-md"
              onClick={toggleSettingsMenu}
            >
              <img className="w-6" src="/icons/settings.png" alt="" />
              <span className="grow">Settings</span>
              <span className="p-1 bg-brandSubtle text-brand rounded rounded-md self-right">
                <AiOutlineRight className="ml-auto" />
              </span>
            </div>

            {/* Popup menu */}
            <div
              ref={settingsRef}
              className={`absolute bottom-14 w-full bg-white shadow-lg border border-gray-200 rounded-md h-auto p-4 ${
                isSettingsOpen ? "block" : "hidden"
              }`}
            >
              <NavLink
                to="/settings/company"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  handleLinkClick("Managing company", e)
                }
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Company</span>
              </NavLink>
              <NavLink
                to="/settings/staff-rates"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  handleLinkClick("Staff Rates", e)
                }
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Staff Rates</span>
              </NavLink>
              <NavLink
                to="/settings/user-management"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  handleLinkClick("User Management", e)
                }
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>User Management</span>
              </NavLink>
              <NavLink
                to="/settings/branding"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  handleLinkClick("Branding", e)
                }
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Branding</span>
              </NavLink>
              <NavLink
                to="/settings/billing"
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  handleLinkClick("Billing", e)
                }
                className="flex items-center gap-x-2 px-3 py-2 text-gray-700 hover:text-brand bg-transparent border border-transparent rounded-md"
              >
                <span>Billing</span>
              </NavLink>
              <br />
            </div>
          </div>
        </div>
        <div className="flex items-center pt-6 border-t border-gray-200">
          <NavLink
            to="/my-profile"
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
              handleLinkClick("My Profile", e)
            }
            className={"flex grow group gap-x-4"}
          >
            <div className="flex items-center p-2 border border-brand rounded-full group-hover:border-brandHover">
              <img className="w-6" src="/icons/user.png" alt="" />
            </div>

            <div className="flex items-start flex-col text-base">
              <span className="text-md text-gray-800">{company?.name}</span>
              <span className="text-sm text-gray-600">
                User: {user?.nickname}
              </span>
            </div>
          </NavLink>

          <span className="group relative">
            <div>
              <img
                  data-tooltip-id="logout"
                  data-tooltip-content="Logout"
                  className="w-6 hover:cursor-pointer"
                  src="/icons/logout.png"
                  alt="Logout"
                  onClick={handleLogout}
                />
                <Tooltip id="logout" />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainNav;
