import React, { useCallback, useEffect, useState } from "react";
import { Project, RequiremtnHighLevelEstimation } from "../store/project/types";
import {
  projectApiUtils,
  useDeleteRequirementMutation,
  useRequirementHighLevelEstimationQuery,
  useUploadProjectRequirementsMutation,
} from "../store/project/projectApiService";
import { useDropzone } from "react-dropzone";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineDelete,
} from "react-icons/ai";
import { logEvent } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { toCurrencyFormat } from "./utils/helperFunctions";
import { setRequirementHighLevelEstimation } from "../store/project/projectSlice";
import { RootState } from "../store";

export const DashboardDataOverview = ({
  project,
}: {
  project: Project | null;
}) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    showDeleteRequiremenFileConfirmation,
    setShowDeleteRequiremenFileConfirmation,
  ] = useState(false);
  const [uploadProjectRequirements] = useUploadProjectRequirementsMutation();
  const [deleteProjectRequirement] = useDeleteRequirementMutation();

  const similarProjects = useSelector<
    RootState,
    RequiremtnHighLevelEstimation[] | null
  >((state) => state.projectData.requirementHighLevelEstimation);

  const { data: requirementHighLevelEstimationData } =
    useRequirementHighLevelEstimationQuery(project?.requirement?.id || "", {
      skip: !project?.requirement?.id,
    });

  const handleDeleteRequirementFileClick = () => {
    setShowDeleteRequiremenFileConfirmation(true);
  };

  useEffect(() => {
    if (requirementHighLevelEstimationData) {
      dispatch(
        setRequirementHighLevelEstimation(
          requirementHighLevelEstimationData.data
        )
      );
    }
  });

  const handleConfirmDeleteRequirementFile = (id: string) => {
    deleteProjectRequirement(id)
      .unwrap()
      .then(() => {
        toast.success("Successfully deleted!");
        setShowDeleteRequiremenFileConfirmation(false);
      })
      .catch((err: any) => {
        toast.error(err?.data?.message || "Error!");
      });
  };

  const handleCancelDeleteRequirementFile = () => {
    setShowDeleteRequiremenFileConfirmation(false);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      uploadProjectRequirements({
        projectId: projectId!,
        file: acceptedFiles[0],
      })
        .unwrap()
        .then(({ data }) => {
          dispatch(
            projectApiUtils.invalidateTags([
              { type: "Project", id: projectId! },
            ])
          );
          logEvent("Requirement", {
            action: "Upload",
            projectId: projectId!,
            fileType: acceptedFiles[0].type,
          });
        })
        .catch((err: any) => {
          toast.error(err?.data?.message || "Error!");
          logEvent("Requirement", {
            action: "Upload Failed",
            projectId: projectId!,
            fileType: acceptedFiles[0].type,
            message: err?.data?.message,
          });
        });
    },
    [projectId, uploadProjectRequirements, dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
    },
  });

  if (!project) {
    return null;
  }

  return (
    <div className="p-6 space-y-4">
      {project.requirement && (
        <div className="group relative text-gray-700 hover:bg-gray-50 even:bg-gray-10 cursor-pointer">
          <h2 className="font-semibold text-gray-900 mb-0 grow">
            Estimation - {project.requirement?.fileName}
          </h2>
          {showDeleteRequiremenFileConfirmation ? (
            <div className="absolute right-12 top-1/2 transform -translate-y-1/2 flex items-center space-x-2">
              <span className="text-sm mr-2">Are you sure?</span>
              <AiOutlineCheck
                className="hover:text-green-500 cursor-pointer"
                onClick={() =>
                  handleConfirmDeleteRequirementFile(project.requirement!.id)
                }
                size={20}
              />
              <AiOutlineClose
                className="hover:text-red-500 cursor-pointer"
                onClick={() => handleCancelDeleteRequirementFile()}
                size={20}
              />
            </div>
          ) : (
            <AiOutlineDelete
              className="cursor-pointer hover:text-red-500 absolute right-12 top-1/2 transform -translate-y-1/2 opacity-0 group-hover:opacity-100"
              onClick={() => handleDeleteRequirementFileClick()}
            />
          )}
        </div>
      )}
      {project.requirement && (
        <div className="bg-gray-100 p-4 rounded-md">
          <p className="text-gray-700">
            <ReactMarkdown>{project.summary || ""}</ReactMarkdown>
          </p>
        </div>
      )}

      {project.requirement?.hasProfessionalServices && (
        <div className="bg-green-50 p-4 rounded-md space-y-4">
          <h3 className="font-semibold text-gray-900">Software Development</h3>
          <p className="text-gray-700">
            <ReactMarkdown>
              {project.requirement.professionalServicesBriefDescription}
            </ReactMarkdown>
          </p>

          {project.requirement.professionalServicesPriceFrom && (
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-800">
                  Estimated Cost:
                </span>
                <span className="text-gray-700">
                  {toCurrencyFormat(20000) || "N/A"}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-800">
                  Estimated Effort:
                </span>
                <span className="text-gray-700">
                  {"40-60" || "N/A"} person-days
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="font-medium text-gray-800">
                  Estimated Duration:
                </span>
                <span className="text-gray-700">{"4-6" || "N/A"} weeks</span>
              </div>
            </div>
          )}
          <div className="space-y-1">
            <h4 className="font-semibold text-gray-900">Similar Projects:</h4>
            {similarProjects && (
              <ul className="list-disc list-inside text-blue-600">
                {similarProjects.map((item) => (
                  <li key={item.id}>
                    <a
                      href={`/projects/${item.id}`}
                      className="underline hover:text-blue-800"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <button
            onClick={() => navigate("/clarify-requirements")}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md w-full mt-4"
          >
            Clarify Requirements
          </button>
        </div>
      )}

      {project.requirement?.hasProcurement && (
        <div className="bg-yellow-50 p-4 rounded-md">
          <h3 className="font-semibold text-gray-900">Hardware</h3>
          <p className="text-gray-700">
            <ReactMarkdown>
              {project.requirement.procurementBriefDescription}
            </ReactMarkdown>
          </p>
        </div>
      )}

      {project.requirement?.hasRequestForInformation && (
        <div className="bg-yellow-50 p-4 rounded-md">
          <h3 className="font-semibold text-gray-900">Licenses</h3>
          <p className="text-gray-700">
            <ReactMarkdown>
              {project.requirement.requestForInformationBriefDescription}
            </ReactMarkdown>
          </p>
        </div>
      )}
      {project.requirement == null && (
        <div className="bg-gray-50 p-4 rounded-md">
          <div {...getRootProps()} className="upload min-h-52">
            <input {...getInputProps()} />
            <div className="text-sm text-center">
              <div className="p-2 my-2 shadow bg-white inline-flex items-center border border-gray-200 rounded rounded-md">
                <img src="/icons/upload.png" className="w-5 h-5" alt="upload" />
              </div>
              <br />
              <span className="text-brand underline font-bold">
                Click to upload
              </span>
              &nbsp;or drag & drop the Requirements, <br /> Supported formats:
              DOCX, PDF
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
