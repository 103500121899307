import React from 'react';
import { NavLink } from 'react-router-dom';

interface Step {
  title: string;
  description: string;
  isCompleted: boolean;
  isCurrent: boolean;
  to: string;
  nextToResolve: boolean;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

interface StepperProps {
  steps: Step[];
}

const Stepper: React.FC<StepperProps> = ({ steps }) => {
  return (
    <div className="nav-wiz flex flex-col items-start pl-4 mt-2 mb-2">
      {steps.map((step, index) => (
        <NavLink
          key={index}
          to={step.to}
          onClick={step.onClick}
          className="flex items-start"
        >
          <div className="flex flex-col items-center mr-4">
            <div
              className={`w-6 h-6 rounded-full flex items-center justify-center ${
                step.isCompleted
                  ? 'bg-green-600 text-white'
                  : step.isCurrent
                  ? 'bg-gray-500 text-white outline outline-[6px] outline-gray-200'
                  : step.nextToResolve
                  ? 'bg-gray-500 text-white'
                  : 'bg-white border border-gray-200'
                  
              }`}
            >
              {step.isCompleted ? (
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              ) : (
                <div className={`${step.isCurrent ? 'w-2 h-2 bg-white rounded-full' : 'w-2 h-2 bg-gray-200 rounded-full'}`}></div>
              )}
            </div>
            {index !== steps.length - 1 && (
              <div
                className={`w-[2px] h-8 my-2 rounded-full ${
                  step.isCompleted ? 'bg-gray-500' : 'bg-gray-200'
                }`}
              ></div>
            )}
          </div>
          <div>
            <div
              className={`font-semibold ${
                step.isCurrent ? 'text-black' : 'text-gray-600'
              }`}
            >
              {step.title}
            </div>
            <div className="text-sm text-gray-500">{step.description}</div>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default Stepper;
