import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HighLevelEstimationTrainingData } from "./types";

interface HighLevelEstimationDataState {
  highLevelEstimations: HighLevelEstimationTrainingData[];
}

const initialState: HighLevelEstimationDataState = {
  highLevelEstimations: [],
};

export const highLevelEstimationSlice = createSlice({
  name: "highLevelEstimationData",
  initialState,
  reducers: {
    setHighLevelEstimations: (
      state,
      action: PayloadAction<HighLevelEstimationTrainingData[]>
    ) => {
      state.highLevelEstimations = action.payload;
    },
    addHighLevelEstimation: (
      state,
      action: PayloadAction<HighLevelEstimationTrainingData>
    ) => {
      state.highLevelEstimations.push(action.payload);
    },
    updateHighLevelEstimation: (
      state,
      action: PayloadAction<HighLevelEstimationTrainingData>
    ) => {
      const index = state.highLevelEstimations.findIndex(
        (estimation) => estimation.id === action.payload.id
      );
      if (index !== -1) {
        state.highLevelEstimations[index] = action.payload;
      }
    },
    deleteHighLevelEstimation: (state, action: PayloadAction<string>) => {
      state.highLevelEstimations = state.highLevelEstimations.filter(
        (estimation) => estimation.id !== action.payload
      );
    },
  },
});

export const {
  setHighLevelEstimations,
  addHighLevelEstimation,
  updateHighLevelEstimation,
  deleteHighLevelEstimation,
} = highLevelEstimationSlice.actions;

export default highLevelEstimationSlice.reducer;
