import React from "react";
import { HighLevelEstimationTrainingData } from "../../store/highLevelEstimationDataSet/types";
import Markdown from "react-markdown";

type ViewDetailsDrawerProps = {
  isOpen: boolean;
  toggleDrawer: () => void;
  estimation: Partial<HighLevelEstimationTrainingData> | null;
};

const ViewDetailsDrawer = ({
  isOpen,
  toggleDrawer,
  estimation,
}: ViewDetailsDrawerProps) => {
  if (!estimation) return null;

  return (
    <div
      className={`fixed top-0 right-0 w-1/3 h-full overflow-auto bg-white shadow-md z-50 transition-transform duration-300 ${
        isOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="m-4 mt-12">
        <h2 className="text-lg font-semibold m-2">Estimation Details</h2>
        <div className="m-4">
          <p className="font-semibold">Scope:</p>
          <Markdown>{estimation.scope || "N/A"}</Markdown>
        </div>
        <div className="m-4">
          <p className="font-semibold">Requirements:</p>
          <Markdown>{estimation.requirements || "N/A"}</Markdown>
        </div>
        <div className="m-4">
          <p className="font-semibold">Complexity:</p>
          <Markdown>{estimation.complexity || "N/A"}</Markdown>
        </div>
        <div className="m-4 mt-8 flex items-center justify-center grow">
          <button
            type="button"
            onClick={toggleDrawer}
            className="button-secondary w-[1/3] ml-4"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsDrawer;
